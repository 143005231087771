const hash = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','0','1','2','3','4','5','6','7','8','9','@','à','è','ì','ò','ù','!',',','.','-','z','y','x','w','v','u','t','s','r','q','p','o','n','m','l','k','j','i','h','g','f','e','d','c','b','a'];

const hashProperties = [
    {"key":"ae","value":"age"},
    {"key":"pi_","value":"player_id"},
    {"key":"kyde","value":"key"},
    {"key":"fn_","value":"first_name"},
    {"key":"ln_","value":"last_name"},
    {"key":"ht","value":"height"},
    {"key":"wt","value":"weight"},
    {"key":"ny","value":"nationality"},
    {"key":"pp_","value":"played_positions"},
    {"key":"pps_","value":"played_positions_short"},
    {"key":"pt_","value":"position_text"},
    {"key":"rc_","value":"region_code"},
    {"key":"ti_","value":"team_id"},
    {"key":"tn_","value":"team_name"},
    {"key":"trn_","value":"team_region_name"},
    {"key":"tti_","value":"tournament_id"},
    {"key":"ttn_","value":"tournament_name"},
    {"key":"trc_","value":"tournament_region_code"},
    {"key":"ttrn_","value":"tournament_region_name"},
    {"key":"tv_","value":"team_value"},
    {"key":"sv_","value":"score_value"},
    {"key":"se","value":"score"},
    {"key":"de_","value":"defence_value"},
    {"key":"de","value":"defence"},
    {"key":"pv_","value":"pass_value"},
    {"key":"ps","value":"pass"},
    {"key":"sev_","value":"save_value"},
    {"key":"se-","value":"save"},
    {"key":"prv_","value":"player_value"},
    {"key":"at","value":"assist"},
    {"key":"ac_","value":"assist_corner"},
    {"key":"atc_","value":"assist_cross"},
    {"key":"af_","value":"assist_freekick"},
    {"key":"ao_","value":"assist_other"},
    {"key":"at_","value":"assist_throughball"},
    {"key":"att_","value":"assist_throwin"},
    {"key":"cl_","value":"challenge_lost"},
    {"key":"ct_","value":"clearance_total"},
    {"key":"dd","value":"dispossessed"},
    {"key":"dl_","value":"dribble_lost"},
    {"key":"dt_","value":"dribble_total"},
    {"key":"dw_","value":"dribble_won"},
    {"key":"dal_","value":"duel_aerial_lost"},
    {"key":"dat_","value":"duel_aerial_total"},
    {"key":"daw_","value":"duel_aerial_won"},
    {"key":"fc_","value":"foul_committed"},
    {"key":"fg_","value":"foul_given"},
    {"key":"gc_","value":"goal_counter"},
    {"key":"gh_","value":"goal_head"},
    {"key":"glf_","value":"goal_left_foot"},
    {"key":"gn_","value":"goal_normal"},
    {"key":"go_","value":"goal_obox"},
    {"key":"glo_","value":"goal_obp"},
    {"key":"gop_","value":"goal_open_play"},
    {"key":"glon_","value":"goal_own"},
    {"key":"gpa_","value":"goal_penalty_area"},
    {"key":"grf_","value":"goal_right_foot"},
    {"key":"gsp_","value":"goal_set_piece"},
    {"key":"gsyb_","value":"goal_six_yard_box"},
    {"key":"gt_","value":"goal_total"},
    {"key":"ia_","value":"interception_all"},
    {"key":"kpc_","value":"key_pass_corner"},
    {"key":"kpcs_","value":"key_pass_cross"},
    {"key":"kpf_","value":"key_pass_freekick"},
    {"key":"kpl_","value":"key_pass_long"},
    {"key":"kpo_","value":"key_pass_other"},
    {"key":"kps_","value":"key_pass_short"},
    {"key":"kpt_","value":"key_pass_throughball"},
    {"key":"kypt_","value":"key_pass_throwin"},
    {"key":"kpst_","value":"key_passes_total"},
    {"key":"mp_","value":"mins_played"},
    {"key":"tmp_","value":"team_mins_played"},
    {"key":"og_","value":"offside_given"},
    {"key":"ob_","value":"outfielder_block"},
    {"key":"obp_","value":"outfielder_blocked_pass"},
    {"key":"pca_","value":"pass_corner_accurate"},
    {"key":"pci_","value":"pass_corner_inaccurate"},
    {"key":"psca_","value":"pass_cross_accurate"},
    {"key":"pcbd_","value":"pass_cross_blocked_defensive"},
    {"key":"psci_","value":"pass_cross_inaccurate"},
    {"key":"pfa_","value":"pass_freekick_accurate"},
    {"key":"pfi_","value":"pass_freekick_inaccurate"},
    {"key":"plba_","value":"pass_long_ball_accurate"},
    {"key":"plbi_","value":"pass_long_ball_inaccurate"},
    {"key":"pat_","value":"pass_total"},
    {"key":"ps_","value":"penalty_scored"},
    {"key":"pyt_","value":"penalty_taken"},            
    {"key":"rdc_","value":"red_card"},
    {"key":"so_","value":"save_obox"},
    {"key":"spa_","value":"save_penalty_area"},
    {"key":"ssyb_","value":"save_six_yard_box"},
    {"key":"st_","value":"save_total"},
    {"key":"stpa_","value":"short_pass_accurate"},
    {"key":"spi_","value":"short_pass_inaccurate"},
    {"key":"sb_","value":"shot_blocked"},
    {"key":"sc_","value":"shot_counter"},
    {"key":"sh_","value":"shot_head"},
    {"key":"slf_","value":"shot_left_foot"},
    {"key":"sot_","value":"shot_obox_total"},
    {"key":"sto_","value":"shot_obp"},
    {"key":"sof_","value":"shot_off_target"},
    {"key":"sop_","value":"shot_on_post"},
    {"key":"stot_","value":"shot_on_target"},
    {"key":"stop_","value":"shot_open_play"},
    {"key":"stpya_","value":"shot_penalty_area"},
    {"key":"srf_","value":"shot_right_foot"},
    {"key":"ssp_","value":"shot_set_piece"},
    {"key":"stsyb_","value":"shot_six_yard_box"},
    {"key":"sst_","value":"shots_total"},
    {"key":"tta_","value":"tackle_total_attempted"},
    {"key":"twt_","value":"tackle_won_total"},
    {"key":"ti_","value":"team_id"},
    {"key":"tr","value":"turnover"}, 
    {"key":"yc_","value":"yellow_card"},
    {"key":"nd_d_i_","value":"normalized_defence_incidence"},
    {"key":"nd_d_g_","value":"normalized_defence_grow"},
    {"key":"nd_d_r_","value":"normalized_defence_rating"},
    {"key":"nd_p_i_","value":"normalized_pass_incidence"},
    {"key":"nd_p_g_","value":"normalized_pass_grow"},
    {"key":"nd_p_r_","value":"normalized_pass_rating"},
    {"key":"nd_s_i_","value":"normalized_save_incidence"},
    {"key":"nd_s_g_","value":"normalized_save_grow"},
    {"key":"nd_s_r_","value":"normalized_save_rating"},
    {"key":"nd_se_i_","value":"normalized_score_incidence"},
    {"key":"nd_se_g_","value":"normalized_score_grow"},
    {"key":"nd_se_r_","value":"normalized_score_rating"},
    {"key":"nd_de_d_","value":"normalized_defence_deviation"},
    {"key":"nd_ps_d_","value":"normalized_pass_deviation"},
    {"key":"nd_se_d_","value":"normalized_save_deviation"},
    {"key":"nd_sc_d_","value":"normalized_score_deviation"},    
    {"key":"akb__","value":"able_keep_ball"},
    {"key":"agbs___","value":"accuracy_goal_by_shots"},
    {"key":"gl","value":"goal"},
    {"key":"ptg_","value":"pass_to_goal"},
    {"key":"ptgbp____","value":"pass_to_goal_by_pass"},
    {"key":"as_","value":"able_shot"},
    {"key":"abg_","value":"able_break_game"},
    {"key":"atk_","value":"able_tackle"},
    {"key":"an","value":"aggression"},
    {"key":"ap_","value":"accurancy_pass"},
    {"key":"ase_","value":"able_save"},
    {"key":"agbfk____","value":"able_goal_by_free_kick"},
    {"key":"agbp___","value":"able_goal_by_penalty"},
    {"key":"sct_","value":"score_coefficient"},
    {"key":"dc_","value":"defence_coefficient"},
    {"key":"pc_","value":"pass_coefficient"},
    {"key":"sdd__","value":"standard_deviation_defence"},
    {"key":"sdp__","value":"standard_deviation_pass"},
    {"key":"sds_","value":"standard_deviation_score"},
    {"key":"sec_","value":"save_coefficient"},
    {"key":"sdns_","value":"standard_deviation_save"},
    {"key":"nd_","value":"normalized_defence"},
    {"key":"np_","value":"normalized_pass"},
    {"key":"ns_","value":"normalized_save"},
    {"key":"nds_","value":"normalized_score"},
    {"key":"ty_","value":"type"},
    {"key":"apps","value":"apps"},
    {"key":"md","value":"modulo"}
];

const translatePosition = positions => {
    let ret = [];
    const start = hash.length - positions;
    for(var i=start; i<hash.length; i++)
        ret.push(hash[i]);
    for(var i=0;i<start;i++)
        ret.push(hash[i]);

    return ret;
};

const arraySearch = (chr,array) => {
    let pos = -1;
    array.map((letter,index) => {
        if(letter === chr)
            pos = index;
    });
    return pos;
};

const getProperty = prop => {
    let res = prop;
    hashProperties.map(property => {
       if(property["key"] == prop)
            res = property["value"];
    });
    return res;
};

const decrypt = player => {
    const crypt = translatePosition(player.kyde);
    let playerDecrypt = {};
    Object.keys(player).map(property => {        
        let value = '';
        let pos = -1;
        if(property !== 'kyde'){
            const arrWord = player[property].split('');
            arrWord.map(chr => {
                pos = arraySearch(chr,crypt);
                if(pos !== -1)
                    value += hash[pos];                
                else
                    value += chr;                
            });
            player[property] = value;

            playerDecrypt[getProperty(property)]=value;
        }        
    });

    return playerDecrypt;
}

export default decrypt;