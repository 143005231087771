import { action, thunk } from 'easy-peasy';
import utils from '../utils';

export default {
    loadingOverview: false,
    setLoadingOverview: action((state, payload) =>{
        state.loadingOverview = payload;
    }),
    playerOverview: null,
    setPlayerOverview: action((state, payload) => {
        state.playersOverview = null;        
        if(!payload)
            return;
        payload.map((player) => {
            state.playerOverview = utils.decryptResponse(player);
        });
    }),
    retrievePlayerOverview: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoadingOverview(true);
        services.players.getPlayerOverview(payload)
            .then(data => {
                actions.setPlayerOverview(data.players);
            })
            .catch(e => {
                console.log("ERRORE ", e);
            })
            .finally(_ => {
                actions.setLoadingOverview(false);
            });
    })
};