import utils from "../utils";

export default ({tournament,team,nation}) => {
    const requestOptions = {
        method: 'GET',
        headers: utils.createHeaders()
    };
    
    return utils.fetch(`${process.env.REACT_APP_API_ENDPOINT}/players/getbyfilters?t=${tournament}&s=${team}&n=${nation}`, requestOptions)
        .then(response => response.json());
};