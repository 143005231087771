import { action, thunk } from 'easy-peasy';
import utils from '../utils';

export default {
    loadingCalcData: false,
    setLoadingCalcData: action((state, payload) => {
        state.loadingCalcData = payload;
    }),
    playerCalcData: null,
    setPlayerCalcData: action((state, payload) => {
        state.playerCalcData = null;        
        if(!payload)
            return;
        payload.map((player) => {
            state.playerCalcData = utils.decryptResponse(player);
        });
    }),
    retrievePlayerCalcData: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoadingCalcData(true);
        services.players.getPlayerCalcData(payload)
            .then(data => {
                actions.setPlayerCalcData(data.players);
            })
            .catch(e => {
                console.log("ERRORE ", e);
            })
            .finally(_ => {
                actions.setLoadingCalcData(false);
            });
    }),
};