import store from "../../store";

export default _ => {
    const { user: {
            user
        } 
    } = store.getState();

    const token = user != null && user.token !== null ? user.token : '';
    const h = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`
        //'withCredentials':true,
        
        /*'Access-Control-Allow-Credentials': false,
        
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With',
        'Access-Control-Allow-Credentials': true
        */
    };

    return h;
};