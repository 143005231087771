import React, {useEffect, useState, useMemo, Fragment} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { Stack,Typography,CircularProgress,Box,Avatar,Button,Modal} from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Groups2Icon from '@mui/icons-material/Groups2';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import FieldVeritcal from '../../components/FieldVertical';
import FieldVeritcalHigh from '../../components/FieldVerticalHigh/index.js';
import deKriptTeamId from '../../components/Utility/deKriptTeamId';
import formazioneByModulo from '../../components/Utility/formazioneByModulo';
import SelectModulo from '../../components/SelectModulo';
import TeamValue from './TeamValue';
import PointsValue from './PointsValue.js';
import PlayersListTable from '../../components/PlayersListTable.js';
import TeamListTable from '../../components/TeamListTable.js';
import Distinta from '../../components/Distinta';
import MessageLogin from '../../components/MessageLogin';
import Banner from '../../components/Banner';
import SocialMediaShare from '../../components/SocialMediaShare';
import MessageHeaderLogin from '../../components/MessageHeaderLogin/index.js';

const Team = props => {
    const params = useParams();
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);    
    const userLogged = user == null ? false : user !== null && user.logged && user.token !== '';
    const showMessageNotLogged = useStoreState(state => state.user.showMessageNotLogged);
    const loadingFind = useStoreState(state => state.players.findPlayer.loading);  
    const players = useStoreState(state => state.players.findPlayer.players);
    const findPlayersByTeam = useStoreActions(dispatch => dispatch.players.findPlayer.findPlayersByTeam);
    const loadingFindTour = useStoreState(state => state.players.findPlayer.loadingFindTour);  
    const playersTournament = useStoreState(state => state.players.findPlayer.playersTournament);
    const findPlayersByTournament = useStoreActions(dispatch => dispatch.players.findPlayer.findPlayersByTournament);    
    const loadingTeams = useStoreState(state => state.teams.getTeams.loading);
    const allTeams = useStoreState(state => state.teams.getTeams.teams);
    const retrieveAllTeams = useStoreActions(dispatch => dispatch.teams.getTeams.retrieveAllTeams);
    const teamTrand = useStoreState(state => state.teams.getTeams.teamTrand);
    const retrieveTeamTrand = useStoreActions(dispatch => dispatch.teams.getTeams.retrieveTeamTrand);
    const imgPath = process.env.REACT_APP_PUBLIC_URL + "/assets/images/team/";
    const noImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/team/noImg.png";
    const teamId = deKriptTeamId(params.teamId);
    const currentTeam = allTeams.filter(t => t.team_id === teamId);
    const [modulo, setModulo] = useState(allTeams && allTeams.length > 0 && currentTeam.length > 0 ? 
        currentTeam[0].modulo.split(',').map(n => parseInt(n, 10)) : [1,4,2,3,1]);
    const [formazione, setFormazione] = useState([]);
    const [formazioneValue, setFormazioneValue] = useState(0);
    const [teamValue, setTeamValue] = useState(0);
    const [teamInfo, setTeamInfo] = useState({"name":"","tournament":"","region":"","modulo":""});    
    const [listTeams,setListTeams] = useState([]);
    const [teamPoints,setTeamPoints] = useState(0);
    const [formazionePoints,setFormazionePoints] = useState(0);
    const [openModal, setOpenModal] = useState(!userLogged);
    const indexRandom = Math.floor(Math.random() * 12);
    const otherTeamsUrl = teamInfo && teamInfo.tournament ? '/teams?to=' + teamInfo.tournament : '/teams';
    const otherPlayersUrl = teamInfo && teamInfo.tournament ? '/players?to=' + teamInfo.tournament : '/players';
    const redirectUrl = process.env.REACT_APP_PUBLIC_URL + "/teams";

    const teamBase = useMemo(() => {
        if(!allTeams || allTeams.length === 0)
            retrieveAllTeams();
        retrieveTeamTrand(teamId);
        return findPlayersByTeam(teamId);
    }, [teamId,findPlayersByTeam,allTeams,retrieveAllTeams]);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const extractPoints = (playerTrand,key) => {
        if(!playerTrand)
            return;
        let trand = "";
         Object.keys(playerTrand).map(property => {
            if(property.startsWith("d") && playerTrand[property] != null)
                trand = playerTrand[property];
        });
        if(trand == "-")
            return "-";
        
        const info = trand.split(";").filter(item => item.startsWith(key + ":"));
        if(info && info.length > 0)
            return parseFloat(info[0].replace(key + ":",""),10);
        else
            return "-";        
    };

    const extractTrandTeam = (players) => {                  
        //Estraggo i dati del trand dei giocatori di cui recuperare i valori
        const playersList = players.map((player) => parseInt(player.player_id));
        const trandItems = teamTrand.filter(item => playersList.includes(item.pi_));
        //Creo la lista dei valori del punteggio
        let trandInfo = [];
        trandItems.map(item => {trandInfo = [...trandInfo,{'playerId':item.pi_,"points":extractPoints(item,"va")}]});
        const punteggioFormazione = trandInfo.reduce((a,v) => a = a + parseFloat(v.points,10),0);
        return punteggioFormazione.toFixed(2);
    };

    useEffect(() => {
        //Se la squadra non è più attiva redirigo alla pagina delle squadre
        if(currentTeam.length == 0)
            window.location.replace(redirectUrl);

        if(players.length > 0){
            setTeamInfo({
                "name":players[0].team_name,
                "tournament":players[0].tournament_name,
                "region":players[0].tournament_region_name,
                "modulo":allTeams.filter(t => t.team_id === teamId)[0].modulo
            });
            const formazioneCurr = formazioneByModulo(players,modulo)
            setFormazione(formazioneCurr);
            const valoreTeam = players.reduce((a,v) => a = a + parseFloat(v.player_value,10),0);
            setTeamValue(valoreTeam.toFixed(2));
            setListTeams(
                allTeams.filter(team => team.tournament_id === players[0].tournament_id)
                    .filter((t,index) => index >= indexRandom && index <= indexRandom + 4)
            );
            //Punteggio team
            if(teamTrand){
                setTeamPoints(extractTrandTeam(players));
            }
            //Recrupero la lista di giocatori dello stesso torneo 
            if(userLogged && 
                (!playersTournament || playersTournament.length === 0 || playersTournament[0].tournament_id !== players[0].tournament_id)) {
                findPlayersByTournament({'tournament':players[0].tournament_id,'limit':40});
            }
        }
    }, [players,modulo]);

    useEffect(() => {
        const valoreFormazione = formazione.reduce((a,v) => a = a + parseFloat(v.player_value,10),0);
        setFormazioneValue(valoreFormazione.toFixed(2));
        if(teamTrand)
            setFormazionePoints(extractTrandTeam(formazione));
    },[formazione]);
    

    const onChangeModulo = (event) => {
        const funArray = num => Number(num);
        const newModulo = Array.from(String(event.target.value),funArray);
        setModulo(newModulo);
        setFormazione(formazioneByModulo(players,newModulo));
    }; 

    return(
        <Stack
            direction="column"
            justifyContent="center" 
            alignItems="center" 
            spacing={0}
            sx={{width:'100%'}}
        >
            {/* Modale per utente non loggato */}
            <Modal
                open={openModal && showMessageNotLogged}
                onClose={handleCloseModal}
            >
                <Box><MessageHeaderLogin onClose={handleCloseModal} /></Box>
            </Modal>

            {loadingFind && (
            <Box sx={{width:'70%',textAlign:'center'}}>
                <CircularProgress size={130} sx={{color:theme.palette.primary.main}} />
            </Box>
            )}
            {!loadingFind && (
            <Fragment>
                <Stack 
                    direction="row" 
                    justifyContent="flex-start" 
                    alignItems="center" 
                    spacing={2}
                    sx={{
                        width:'100%',
                        backgroundColor:theme.palette.custom.black,
                        padding:{xs:'10px 10px 10px 10px',md:'10px 120px',sm:'10px 40px',xl:'10px 400px'}
                    }}
                >
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{width:'105%'}}>
                        <Avatar 
                            alt={teamInfo.name} 
                            src={imgPath + teamId + ".png"}
                            onError={(e)=>{e.target.onError=null; e.target.src=noImg}}
                            sx={{
                                borderStyle:'solid',
                                borderWidth:'0px',
                                borderColor:theme.palette.custom.white,
                                width:{xs:'60px',md:'60px',sm:'56px'},
                                height:{xs:'60px',md:'60px',sm:'56px'}
                            }}
                        />
                        <Stack 
                            direction="column" 
                            justifyContent="flex-start" 
                            alignItems="flex-start"
                            spacing={0}
                        >
                            <Typography 
                                variant='titleSection'
                                sx={{
                                    fontSize:{xs:'23px',md:'30px',sm:'22px'},
                                    fontWeight:'600',
                                    color:theme.palette.custom.white,                                
                                }}
                            >
                                {teamInfo.name}
                            </Typography>
                            <Typography 
                                variant='titleSection'
                                sx={{
                                    fontSize:{xs:'18px',md:'24px',sm:'18px'},
                                    fontWeight:'400',
                                    color:theme.palette.custom.white
                                }}
                            >
                                {teamInfo.tournament + " (" + teamInfo.region + ")"}
                            </Typography> 
                        </Stack>
                    </Stack>                    
                </Stack>

                {/* Sezione 1 */}
                <Stack 
                    direction={{xs:'column',md:'row',sm:'column'}}
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={2}
                    sx={{
                        width:'100%',                    
                        padding:{xs:'20px 10px 10px 10px',md:'20px 120px',sm:'20px 40px',xl:'10px 400px'},                        
                    }}
                >
                    {/* Sezione sx */}
                    <Stack 
                        direction="column" 
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}
                        sx={{
                            width:{xs:'100%',md:'65%',sm:'100%'},
                            paddingLeft:{xs:'0',md:'0',sm:'20px'}
                        }}
                    >
                        {/* Valore team */}
                        <Stack 
                            direction={{xs:'column',md:'column',sm:'column'}}
                            justifyContent="space-between" 
                            alignItems="flex-start" 
                            spacing={1} 
                            sx={{width:'100%'}}
                        >
                            {/* 
                            <Stack 
                                direction="column" 
                                justifyContent="flex-start" 
                                alignItems="flex-start" 
                                spacing={0.5}
                                sx={{width:'100%'}}                                
                            >
                                <Typography
                                    variant='titleSection'
                                    sx={{
                                        color:theme.palette.custom.black,
                                        fontSize:{xs:'20px',md:'28px',sm:'26px'}
                                    }}
                                >
                                    {t("global.valueaPointsAgregate")}
                                </Typography>
                                <Typography
                                    variant='titleSection'
                                    sx={{
                                        color:theme.palette.custom.black,
                                        fontSize:{xs:'15px',md:'15px',sm:'15px'}
                                    }}
                                >
                                    {t("global.valueaggregatedesc")}
                                </Typography>
                            </Stack>                            
                            */}
                            <Typography
                                variant='titleSection'
                                sx={{
                                    color:theme.palette.custom.black,
                                    fontSize:{xs:'20px',md:'28px',sm:'26px'}
                                }}
                            >
                                {t("global.valueaPointsAgregate")}
                            </Typography>
                            {!userLogged && (<MessageLogin data={""} color={theme.palette.custom.black} />)}
                            {userLogged && (
                                 <Stack 
                                    direction="row" 
                                    justifyContent="flex-start" 
                                    alignItems="flex-start" 
                                    spacing={2}                                     
                                >
                                    <TeamValue value={teamValue} /> 
                                    <Typography
                                        variant='titleSection'
                                        sx={{
                                            color:theme.palette.custom.black,
                                            fontSize:{xs:'20px',md:'36px',sm:'26px'},
                                            fontWeight:'400'
                                        }}
                                    >|</Typography>
                                    <PointsValue value={teamPoints} />
                                </Stack>
                            )}
                           
                        </Stack>

                        {/* Valori formazione Modulo e campo */}
                        <Stack 
                            direction="column" 
                            justifyContent="flex-start" 
                            alignItems="flex-start" 
                            spacing={0.5}
                            sx={{width:'100%',marginTop:'30px !important'}}
                        >
                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                <GroupAddIcon />
                                <Typography
                                    variant='titleSection'
                                    sx={{
                                        color:theme.palette.custom.black,
                                        fontSize:{xs:'20px',md:'20px',sm:'18px'},
                                    }}
                                >
                                    {t("global.lineupbestvalue")}
                                </Typography>
                            </Stack>
                            <Typography
                                variant='titleSection'
                                sx={{
                                    color:theme.palette.custom.black,
                                    fontSize:{xs:'15px',md:'15px',sm:'15px'},  
                                }}
                            >
                                {t("global.lineupbestvaluedesc")}
                            </Typography>
                            <Stack 
                                direction="column" 
                                justifyContent="center" 
                                alignItems="center" 
                                spacing={2} 
                                sx={{width:'100%',marginTop:'10px !important'}}
                            >
                                <Stack 
                                    direction="row" 
                                    justifyContent="space-between" 
                                    alignItems="center" 
                                    spacing={3} 
                                    sx={{width:'100%',marginTop:'25px !important'}}
                                >
                                    {/* Modulo */}
                                    <SelectModulo 
                                        modulo={modulo} 
                                        onChangeModulo={onChangeModulo} 
                                        disabled={!userLogged} 
                                        width={{xs:'200px',md:'220px',sm:'220px'}}
                                    />
                                                                    
                                    {/* Valore e punteggio formazione */}                                
                                    {!userLogged && (
                                        <Box>
                                            <MessageLogin data={""} color={theme.palette.custom.black} />
                                        </Box>
                                    )}
                                    {userLogged && (
                                        <Stack 
                                            direction="row" 
                                            justifyContent="flex-start" 
                                            alignItems="flex-start" 
                                            spacing={1}                                             
                                        >
                                            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                                <Typography 
                                                    variant='titleSection'
                                                    sx={{
                                                        fontSize:{xs:'30px',md:'40px',sm:'34px'},
                                                        color:theme.palette.custom.blueIcon,
                                                        fontWeight:'600'
                                                    }}
                                                >
                                                    {formazioneValue}
                                                </Typography>
                                                <Typography
                                                    variant='titleSection'
                                                    sx={{
                                                        color:theme.palette.custom.black,
                                                        fontSize:'16px !important',
                                                        fontWeight:'500'      
                                                    }}
                                                >
                                                    Mio €
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                variant='titleSection'
                                                sx={{
                                                    color:theme.palette.custom.black,
                                                    fontSize:{xs:'20px',md:'36px',sm:'26px'},
                                                    fontWeight:'400'
                                                }}
                                            >|</Typography>
                                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-end" spacing={0}>
                                                <Typography 
                                                    variant='titleSection'
                                                    sx={{
                                                        fontSize:{xs:'30px',md:'40px',sm:'34px'},
                                                        color:theme.palette.custom.gk,
                                                        fontWeight:'600'
                                                    }}
                                                >
                                                    {formazionePoints}
                                                </Typography>
                                                <Typography
                                                    variant='titleSection'
                                                    sx={{
                                                        color:theme.palette.custom.black,
                                                        fontSize:'16px !important',
                                                        fontWeight:'500'      
                                                    }}
                                                >
                                                    {t('global.points')}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                     )}
                                </Stack>                                                   
                                
                                {/* Campo */}
                                <Stack 
                                    direction={{xs:'column',md:'row',sm:'row'}} 
                                    justifyContent="space-between" 
                                    alignItems="center"
                                    spacing={1}
                                    className='wrapperBorder'
                                    sx={{width:'100%'}}
                                >
                                    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                                        {/* Messaggio click sui giocatori */}
                                        <Typography
                                            variant='text'
                                            sx={{fontSize:'15px !important',color:theme.palette.custom.black,fontWeight:'600'}}
                                        >
                                            {t('global.verticalfieldclick')}
                                        </Typography>
                                        <FieldVeritcalHigh players={formazione} modulo={modulo} />
                                    </Stack>
                                    
                                    <Box 
                                        sx={{
                                            width:{xs:'100%',md:'50%',sm:'60%'},
                                            paddingLeft:{xs:'0',md:'20px !important',sm:'0 !important'}
                                        }}
                                    >
                                        <Distinta players={formazione} modulo={modulo} />
                                    </Box>
                                </Stack>
                            </Stack> 
                            
                        </Stack>

                        {/* Lista giocatori */}
                        <Stack 
                            direction="row" 
                            justifyContent="flex-start" 
                            alignItems="center" 
                            spacing={1}
                            sx={{marginTop:{xs:'20px !important',md:'40px !important',sm:'30px !important'}}}
                        >
                            <Groups2Icon />
                            <Typography
                                variant='titleSection'
                                sx={{
                                    color:theme.palette.custom.black,
                                    fontSize:{xs:'20px',md:'20px',sm:'20px'},
                                }}
                            >
                                {t("global.teamroster")} ({players.length})
                            </Typography>
                        </Stack>                        
                        <Typography
                            variant='titleSection'
                            sx={{
                                color:theme.palette.custom.black,
                                fontSize:{xs:'15px',md:'15px',sm:'15px'},  
                            }}
                        >
                            {t("global.teamrosterdesc")}
                        </Typography>
                        {!userLogged && (<MessageLogin data={t("messages.roster")} color={theme.palette.custom.black} />)}
                        {userLogged && (
                            <Box 
                                sx={{width:{xs:'100%',md:'600px',sm:'600px'},maxWidth:'100%'}}
                            >
                                <PlayersListTable players={players} />
                            </Box>
                        )}
                    </Stack>

                    {/* sezione dx */} 
                    <Stack 
                        direction="column" 
                        justifyContent="center"
                        alignItems={{xs:"center",md:"flex-end",sm:"center"}}
                        spacing={3}
                        sx={{
                            width:{xs:'100%',md:'35%',sm:'100%'},
                            marginTop:{xs:'50px !important',md:'0px !important',sm:'50px !important'}
                        }}
                    >
                        {/* Social */}
                        <SocialMediaShare 
                            url={window.location.href} 
                            quote={t("social.share.teams")} 
                            hashtag={[t("social.hashtag.calciomercato"),t("social.hashtag.calcio")]}
                            propsadd={{marginBottom:'20px'}}
                        />

                        {/* Banner */}
                        <Banner type={'300x300_3'} />

                        {/* Altre squadre */}
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{width:{xs:'100%',md:'75%',sm:'65%'},marginTop:'50px !important'}}
                        >                            
                            <Typography variant='titleAside'>{parse(t("pagine.team.othersteam"))}</Typography>
                            <Typography variant='titleAside' sx={{fontSize:'21px !important'}}>{teamInfo.tournament}</Typography>
                            <Box sx={{width:'100%',textAlign:'right',margin:'-20px 0 0 0 !important'}}>
                                <Button 
                                    variant='textAction'
                                    endIcon={<KeyboardDoubleArrowRightIcon />}
                                    href={otherTeamsUrl}
                                >
                                    {t("global.veditutte")}
                                </Button>
                            </Box>                            
                            {loadingTeams && (<CircularProgress size={24} sx={{color:theme.palette.primary.main}} />)}
                            {!loadingTeams && (<TeamListTable teamsList={listTeams} linkpage={true} />)}
                        </Stack>
                        
                        {/* Banner */}
                        <Box sx={{marginTop:'50px !important'}}>
                            <Banner type={'300x300_2'} />
                        </Box>

                        {/* Altri giocatori dello stesso torneo */}
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{width:{xs:'100%',md:'75%',sm:'70%'},marginTop:'50px !important'}}
                        > 
                            <Typography variant='titleAside'>{parse(t("pagine.player.othersplayers"))}</Typography>
                            <Typography variant='titleAside' sx={{fontSize:'21px !important'}}>{teamInfo.tournament}</Typography>
                            <Box sx={{
                                width:'100%',
                                textAlign:'right',
                                margin:{xs:'-15px 0 !important',md:'-20px 0 !important',sm:'-20px 0 !important'}
                            }}
                            >
                                <Button 
                                    variant='textAction'
                                    endIcon={<KeyboardDoubleArrowRightIcon />}
                                    href={otherPlayersUrl}
                                >
                                    {t("global.veditutti")}
                                </Button>
                            </Box>
                            {!userLogged && (
                                <Box sx={{marginTop:'20px !important'}}>
                                    <MessageLogin data={""} color={theme.palette.custom.black} />
                                </Box>
                            )}
                            {userLogged && loadingFindTour && (<CircularProgress size={24} sx={{color:theme.palette.primary.main}} />)}
                            {userLogged && !loadingFindTour && (
                                <PlayersListTable 
                                    players={playersTournament.filter((p,index) => p.team_id !== teamId).filter((p,i) => i < 5)} 
                                    viewTeam={true} 
                                    viewRole={false}
                                />          
                            )}
                        </Stack>

                        {/* Banner */}
                        <Box 
                            sx={{
                                marginTop:'50px !important',
                                marginBottom:{xs:'40px !important',md:'0 !important',sm:'40px !important'}
                            }}
                        >
                            <Banner type={'300x300_1'} />
                        </Box>

                    </Stack>
                </Stack>
            </Fragment>
            )}
        </Stack>
    );
};

export default Team;