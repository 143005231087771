import React, {useEffect, useState, Fragment} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PersonIcon from '@mui/icons-material/Person';
import DialogRegister from './DialogRegister';

const FormSignIn = props => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const loading = useStoreState(state => state.user.loading);    
    const errorRegistred = useStoreState(state => state.user.errorRegistred);    
    const setErrorRegistred = useStoreActions(dispatch => dispatch.user.setErrorRegistred);
    const register = useStoreActions(dispatch => dispatch.user.register);
    const setOpenMenu = useStoreActions(dispatch => dispatch.user.setOpenMenu);    
    const openDialogRegistred = useStoreState(state => state.user.openDialogRegistred);
    const setOpenDialogRegistred = useStoreActions(dispatch => dispatch.user.setOpenDialogRegistred);
    const privacy = parse(t("formlogin.privacy"));
    const name = parse(t("global.name"));
    const headerSignIn = parse(t("formlogin.headersignin"));
    const passwordconfirm = parse(t("formlogin.passwordconfirm"));
    const fieldRequired = parse(t("formlogin.fieldrequired"));
    const passwordNotConfirmed = parse(t("formlogin.errorconfirmpassword"));
    const emailFormatError = parse(t("formlogin.errorformatemail"));
    const passwordFormatError = parse(t("formlogin.errorformatpassword"));
    const signInMessage = errorRegistred === '' ? headerSignIn : parse(t("formlogin." + errorRegistred));
    const colorSignInMessage = errorRegistred === '' ? '#000' : theme.palette.error.main;
    //const [openDialogRegistred,setOpenDialogRegistred] = useState(false);
    const [values,setValues] = useState({
        email:'',
        name:'',
        errorName:'',
        password:'',
        passwordConfirm:'',
        errorPassword:'',
        errorPasswordConfirm:'',
        errorSignin:'',
        checked: false,
        errorPrivacy: ''
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        if(errorRegistred !== '')
            setErrorRegistred('');
    };

    const handleChecked = (event) => {
        values.checked = !values.checked;
    };

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    function isValidPassword(password){
        const uppercaseRegExp   = /(?=.*?[A-Z])/;
        const lowercaseRegExp   = /(?=.*?[a-z])/;
        const digitsRegExp      = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp   = /.{8,}/;

        return password.length > 0 && 
            uppercaseRegExp.test(password) &&
            lowercaseRegExp.test(password) &&
            digitsRegExp.test(password) &&
            specialCharRegExp.test(password) &&
            minLengthRegExp.test(password);                
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        //setErrorRegistred('')
        values.errorEmail = '';
        values.errorName = '';
        values.errorPassword = '';
        values.errorPasswordConfirm = '';
        values.errorPrivacy = '';
        setValues({
            ...values,
            errorEmail: values.email.trim() === '' ? fieldRequired : 
                !isValidEmail(values.email) ? emailFormatError : '',
            errorName: values.name.trim() === '' ? fieldRequired : '',
            errorPassword: values.password.trim() === '' ? fieldRequired : 
                !isValidPassword(values.password) ? passwordFormatError : '',
            errorPasswordConfirm: values.passwordConfirm.trim() === '' ? fieldRequired : 
                values.password !== values.passwordConfirm ? passwordNotConfirmed : '',
            errorPrivacy: !values.checked ? fieldRequired : ''
        });
        if(values.errorEmail == '' && values.errorPassword == '' 
            && values.errorName == '' && values.errorPasswordConfirm == '' && values.errorPrivacy == '' && values.checked
            && values.email !== '' && values.password !== '' && values.passwordConfirm !== '' 
            && values.password === values.passwordConfirm && values.name !== '')
        {
            register({
                email: values.email,
                password: values.password,
                name: values.name,
                lang: i18n.language
            });            
        }
        
    };

    const handleCloseDialogRegister = () => {
        //Chiudo si la dialog box che il menù utente
        setOpenDialogRegistred(false);
        //setOpenMenu(false);
    };

    useEffect(() => {
        setOpenDialogRegistred(false);
        setErrorRegistred('');
    },[]);

    return(
    <Fragment>
        <Stack>
            <Box sx={{borderBottom:'1px solid #e5e5e5',textAlign:'center',padding:'12px 0',marginBottom:'15px'}}>
                <Typography 
                    variant='textBase' 
                    sx={{fontSize:'14px',padding:'0 25px',color:colorSignInMessage}}                     
                >
                    {signInMessage}
                </Typography>
            </Box>
            <Stack sx={{padding:'20px 30px'}}>
                <FormControl variant='standard'>
                    <InputLabel htmlFor='formsignin-email' sx={{fontSize:'18px'}}>Email</InputLabel>
                    <Input
                        id="formsignin-email"
                        aria-describedby="formsignin-email-error"
                        value={values.email}
                        onChange={handleChange('email')}
                        startAdornment={
                            <InputAdornment position="start">
                                <AlternateEmailIcon />
                            </InputAdornment>
                        }
                        variant='formLogin'
                    />
                    <FormHelperText 
                        id="formsignin-email-error"
                        variant='formValidate'   
                    >
                        {values.errorEmail}
                    </FormHelperText>
                </FormControl>
                <FormControl variant='standard'>
                    <InputLabel htmlFor='formsignin-name' sx={{fontSize:'18px'}}>{name}</InputLabel>
                    <Input
                        id="formsignin-name"
                        aria-describedby="formsignin-name-error"
                        value={values.name}
                        onChange={handleChange('name')}
                        startAdornment={
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        }
                        variant='formLogin'
                    />
                    <FormHelperText 
                        id="formsignin-name-error"
                        variant='formValidate'   
                    >
                        {values.errorName}
                    </FormHelperText>
                </FormControl>
                <FormControl variant='standard' sx={{marginTop:'10px',color:`${theme.palette.error.main}`}}>
                    <InputLabel htmlFor='formlogin-password' sx={{fontSize:'18px'}}>Password</InputLabel>
                    <Input
                        id="formsignin-password"
                        aria-describedby="formsignin-password-error"
                        type={'text'}
                        value={values.password}
                        onChange={handleChange('password')}
                        error={false}
                        startAdornment={
                            <InputAdornment position="start">
                                <VpnKeyIcon />
                            </InputAdornment>
                        }
                        variant='formLogin'     
                    />
                    <FormHelperText 
                        id="formsignin-password-error"
                        variant='formValidate'
                    >
                        {values.errorPassword}
                    </FormHelperText>
                </FormControl>
                <FormControl variant='standard' sx={{marginTop:'10px',color:`${theme.palette.error.main}`}}>
                    <InputLabel htmlFor='formlogin-passwordconfirm' sx={{fontSize:'18px'}}>{passwordconfirm}</InputLabel>
                    <Input
                        id="formsignin-passwordconfirm"
                        aria-describedby="formsignin-passwordconfirm-error"
                        type={'text'}
                        value={values.passwordConfirm}
                        onChange={handleChange('passwordConfirm')}
                        error={false}
                        startAdornment={
                            <InputAdornment position="start">
                                <VpnKeyIcon />
                            </InputAdornment>
                        }
                        variant='formLogin'      
                    />
                    <FormHelperText 
                        id="formsignin-passwordconfirm-error"
                        variant='formValidate'
                    >
                        {values.errorPasswordConfirm}
                    </FormHelperText>
                </FormControl>     
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="formsignin-privacy"
                                aria-describedby="formsignin-privacy-error"
                                checked={values.checkedchecked}
                                onChange={handleChecked}
                                inputProps={{ 'aria-label': 'controlled' }}
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<TaskAltIcon sx={{color:`${theme.palette.custom.green}`}} />}                                
                            />
                        }
                        label={privacy}   
                        variant='formValidate'  
                    />
                    <FormHelperText 
                        id="formsignin-privacy-error"
                        variant='formValidate'
                        sx={{marginTop:'-10px'}}
                    >
                        {values.errorPrivacy}
                    </FormHelperText>
                </FormControl>           
            </Stack>
            <Button variant='iconFull' onClick={handleSubmit} sx={{marginTop:'-10px'}}>
                <Stack 
                    direction="row" 
                    spacing={0}
                    alignItems="center" 
                    justifyContent="center"        
                >
                    <Box sx={{
                        padding:'0 20px',
                        lineHeight:'60px',
                        position:'absolute',
                        left:'0',
                        backgroundColor:`${theme.palette.custom.yellowDark}`
                        }}
                    >
                        <PersonIcon />
                    </Box>
                    {loading && (<CircularProgress sx={{color:'#fff'}} />)}
                    {!loading && (
                    <Typography variant='text' sx={{fontWeight:'600'}}>
                        {t("formlogin.signin")}
                    </Typography>
                    )}
                </Stack>    
            </Button>  
        </Stack>
        {openDialogRegistred && <DialogRegister open={openDialogRegistred} onClose={handleCloseDialogRegister} values={values} />}
    </Fragment>
    );
};

export default FormSignIn;