import React, {useEffect, useState, Fragment} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { Stack,Typography,Box,CircularProgress,Pagination,TextField,Autocomplete,IconButton,
    Button,Popover,Modal} from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import TuneIcon from '@mui/icons-material/Tune';
import TeamListTable from '../../components/TeamListTable.js';
import PlayersListTable from '../../components/PlayersListTable.js';
import SelectTournament from './SelectTournament';
import Banner from '../../components/Banner';
import SocialMediaShare from '../../components/SocialMediaShare';
import MessageHeaderLogin from '../../components/MessageHeaderLogin/index.js';

const Teams = props => {    
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);  
    const userLogged = user == null ? false : user !== null && user.logged && user.token !== '';  
    const showMessageNotLogged = useStoreState(state => state.user.showMessageNotLogged)
    const loading = useStoreState(state => state.teams.getTeams.loading);
    const allTeams = useStoreState(state => state.teams.getTeams.teams);
    const retrieveAllTeams = useStoreActions(dispatch => dispatch.teams.getTeams.retrieveAllTeams);
    const loadingSummary = useStoreState(state => state.players.playerSummary.loading); 
    const playerSummary = useStoreState(state => state.players.playerSummary.playersLight);        
    const retrievePlayerSummary = useStoreActions(dispatch => dispatch.players.playerSummary.retrievePlayerSummary);  
    const selectedTournament = useStoreState(state => state.teams.getTeams.selectedTournament);
    const setSelctedTournament = useStoreActions(dispatch => dispatch.teams.getTeams.setSelctedTournament);
    const [page, setPage] = useState(1);    
    const [count, setCount] = useState(null);
    const [listTeams, setListTeams] = useState(allTeams && allTeams.length > 0 ? allTeams.slice(0,25) : []);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    //const [selectedTournament, setSelctedTournament] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [text, setText] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(!userLogged);
    const openFilter = Boolean(anchorEl);
    const indexRandom = Math.floor(Math.random() * 30);
    
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const filterTeams = (newpage,lista) => {        
        setListTeams(
            lista.filter((team,index) => index >= (newpage - 1) * rowsPerPage && 
                index < ((newpage - 1) * rowsPerPage) + rowsPerPage)
        );
    };

    const filterByTournament = (lista, tournament) => {
        if (!tournament || tournament.length === 0)
            return lista;
        return lista.filter((team) => team.tournament_name === tournament[0]);
    };

    const filterByText = (lista, value) => {
        return lista.filter((team,index) => 
            team.team_name.toLowerCase().includes(value.toLowerCase()) || 
            team.tournament_name.toLowerCase().includes(value.toLowerCase()) || 
            team.team_region_name.toLowerCase().includes(value.toLowerCase()));
    };

    useEffect(() => {
        setText('');
        //setSelctedTournament(null);
        retrieveAllTeams();
        setPage(1);
        setSelectedTeam(null);
    }, []);

    useEffect(() => {      
        const newLista = selectedTournament ? filterByTournament(allTeams,selectedTournament) : allTeams; 
        setListTeams(newLista.slice(0,25));
        setCount(newLista.length);        
        retrievePlayerSummary(40);
    }, [allTeams.length]);

    const handleNewValue = (event,value) => {
        //Caso in cui si clicca sulla "X" dell'autocomplete
        if(!value)
        {         
            setPage(1);
            const newLista = filterByTournament(allTeams,selectedTournament); 
            filterTeams(1,newLista);
            setText('');
            setSelectedTeam(null);
            setCount(newLista.length);
            return;              
        }
        //Caso in cui è stato scelto un elemento dall'autocomplete
        if(value.team_id){
            setSelectedTeam(value);
            setListTeams([value]);
            setCount(1);
            setText(value.team_name + " (" + value.tournament_name + " - " + value.team_region_name + ")");            
            return;
        }
        //Caso in cui non è stato scelto un elemento dall'autocomplete (press su Enter)
        else{            
            const newList = allTeams.filter((team,index) => 
                team.team_name.toLowerCase().includes(value.toLowerCase()) || 
                team.tournament_name.toLowerCase().includes(value.toLowerCase()) || 
                team.team_region_name.toLowerCase().includes(value.toLowerCase()));            
            const newListFiltered = filterByTournament(filterByText(allTeams,value),selectedTournament);
            setListTeams(newListFiltered);
            setSelectedTeam(null);
            setText(value);
            setPage(1);
            setCount(newListFiltered.length);
        }
    };

    const onClickSearch = _ => {
        const valore = document.getElementById("textfind").value;
        if(!valore || valore == '')
        {
            setPage(1);
            const newLista = selectedTournament ? filterByTournament(allTeams,selectedTournament) : allTeams; 
            filterTeams(1,newLista);
            setText('');
            setCount(newLista.length);
            return;
        }        
        const newList = selectedTeam && selectedTeam.team_id ?
            filterByTournament(allTeams.filter(team => team.team_id == selectedTeam.team_id),selectedTournament) :
            filterByTournament(filterByText(allTeams,valore),selectedTournament);
        setListTeams(newList);
        setText(valore);
        setPage(1);
        setCount(newList.length);        
    };

    const resetSearch = _ => {
        setPage(1);
        filterTeams(1,allTeams);
        setText('');
        setCount(allTeams.length);
        return;   
    };

    const handleChangePage = (event, newPage) => {
        //Se c'è un testo di ricerca devo rifiltrare l'elenco dei calciatori
        const newList = text && text !== '' ? filterByText(allTeams,text) : allTeams;
        //Filtro per torneo       
        const newListFilterd = filterByTournament(newList,selectedTournament);
        setPage(newPage);
        filterTeams(newPage, newListFilterd);
    };

    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const applyFilter = (listTournamentSelected) => {
        setAnchorEl(null);
        setSelctedTournament(listTournamentSelected);        

        //Filtro per eventuale testo di ricerca
        const valore = document.getElementById("textfind").value;
        const newList = selectedTeam && selectedTeam.team_id ? allTeams.filter(team => team.team_id == selectedTeam.team_id) :
            valore && valore !== '' ? filterByText(allTeams,valore) : allTeams;
        //Filtro per torneo
        const newListFilterd = filterByTournament(newList,listTournamentSelected);       
        /*Il testo non lo imposto più perchè mantengo i filtri di torneo e suqadra nel popover  
        const newText = listTournamentSelected.length > 0 ? listTournamentSelected[0] : '';
        */
        //if(newList.length > 0){
            setListTeams(newListFilterd);
         //   setText(newText);
            setPage(1);
            setCount(newListFilterd.length);   
        //}
    };

    const resetAllFilters = (event) => {
        //Resetto eventuali parametri di querystring (caso in cui si arriva da una pagina team)
        searchParams.set('to', []);        
        setSearchParams(searchParams);
        //Resetto i filtro
        setSelctedTournament(null);
        setPage(1);
        setText('');        
        filterTeams(1,allTeams);
        setCount(allTeams.length);
    };

    //Se arrivo da una pagina team fitro il torneo
    useEffect(() => {   
        if(searchParams && searchParams.get('to')){
            applyFilter([searchParams.get('to')]);
            const newListFilterd = filterByTournament(allTeams,[searchParams.get('to')]);
            setSelctedTournament([searchParams.get('to')]);
            setListTeams(newListFilterd);
            setText('');   
            setPage(1);
            setCount(newListFilterd.length);  
        }
    },[searchParams]);

    return(
        <Stack>
            {/* Modale per utente non loggato */}
            <Modal
                open={openModal && showMessageNotLogged}
                onClose={handleCloseModal}
            >
                <Box><MessageHeaderLogin onClose={handleCloseModal} /></Box>
            </Modal>

            {/** Titolo pagina */}
                <Box sx={{
                width:'100%',
                backgroundColor:theme.palette.custom.black,
                color:theme.palette.custom.white,
                padding:{xs:'10px 20px', md:'10px 90px',sm:'10px 40px',tb:'10px 40px'}
            }}>
                <Typography variant='pageTitle'>{t('global.teams')}</Typography>
            </Box> 
            <Stack
                direction={{xs:'column',md:'row',sm:'column'}}
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                sx={{padding:{xs:'40px 20px', md:'40px 90px',sm:'40px 40px',tb:'40px 40px'}}}
            >
                {/* Sezione sx */}
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    sx={{
                        width:{xs:'100%',md:'70%'},
                        paddingRight:{xs:'0',md:'30px'}
                    }}
                >                   
                    {loading && (
                    <Box sx={{width:'100%',minHeight:'100vh',textAlign:'center'}}>
                        <CircularProgress sx={{color:theme.palette.primary.main}} size={130} />
                    </Box>
                    )}
                    {!loading && (
                    <Stack 
                        direction="column" 
                        justifyContent="flex-start" 
                        alignItems="flex-start" 
                        spacing={2}
                        sx={{width:'100%'}}
                    >
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
                            <Autocomplete 
                                id="textfind"
                                variant="search"
                                sx={{width:{xs:270,md:350,sm:350}}}
                                freeSolo
                                disabled={!userLogged}
                                value={text}
                                loading={loading}
                                options={filterByTournament(allTeams,selectedTournament)}                    
                                getOptionLabel={(option) => {
                                    if(option.team_name) 
                                        return option.team_name + " (" + option.tournament_name + " - " + option.team_region_name + ")";
                                    else
                                        return option;
                                }}
                                 onInputChange={(event, newInputValue) => {
                                    if(!newInputValue || newInputValue == '')
                                        resetSearch();
                                }}
                                onChange={(event, newValue) => {handleNewValue(event, newValue)}}                        
                                renderInput={(params) =>(
                                    <TextField                                        
                                        {...params}                                
                                        label={userLogged ? t("pagine.teams.searchtext") : t("messages.logforuse")}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment:(
                                                <Fragment>
                                                    {loading ? <CircularProgress sx={{color:theme.palette.primary.main}} size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </Fragment>
                                            )
                                        }}
                                    />
                                )}
                            />
                            <IconButton onClick={onClickSearch} disabled={!userLogged}>
                                <ManageSearchIcon sx={{color:theme.palette.custom.blueIcon,fontSize:'50px'}} />
                            </IconButton>
                        </Stack>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                            sx={{width:'100%'}}
                        >
                            <Stack 
                                direction="row" 
                                justifyContent="flex-start" 
                                alignItems="flex-start" 
                                spacing={4}
                                sx={{width:{xs:'100%',md:'100%',sm:'100%',tb:'70%'}}}
                            >                            
                                <Box>                                
                                    <Button
                                        size='large'
                                        startIcon={<TuneIcon />}
                                        sx={{color:selectedTournament ? theme.palette.primary.main : theme.palette.custom.blueIcon}}
                                        disabled={!userLogged}
                                        onClick={handleFilterClick}
                                    >
                                        {t("global.torneo")}
                                    </Button>
                                    <Popover
                                        anchorEl={anchorEl}
                                        open={openFilter}
                                        onClose={handleFilterClose}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <SelectTournament 
                                            teams={allTeams} 
                                            selectedTournament={selectedTournament}
                                            applyFilter={applyFilter}
                                            handleFilterClose={handleFilterClose} 
                                        />
                                    </Popover>                                
                                </Box>
                                {/* Reset filtri */}
                                <Button
                                    size='large'
                                    startIcon={<FilterListOffIcon />}
                                    onClick={resetAllFilters}
                                    disabled={!userLogged}
                                    sx={{color:theme.palette.custom.blueIcon}}
                                >
                                    {t('global.resetfilter')}
                                </Button>
                            </Stack>
                            {selectedTournament && (
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="baseline"
                                    spacing={0.5}        
                                    sx={{paddingLeft:{xs:'20px',md:'10px',sm:'10px'}}}                        
                                >
                                    <SubdirectoryArrowLeftIcon sx={{rotate:'90deg',color:theme.palette.primary.main}} />
                                    <Typography variant='textBase' sx={{color:theme.palette.primary.main}}>
                                        {t("pagine.teams.editFilters")}
                                    </Typography>
                                    <SubdirectoryArrowRightIcon sx={{rotate:'270deg',color:theme.palette.primary.main}} />
                                </Stack>
                            )}
                        </Stack>
                        <Box sx={{width:{xs:'70%',md:'55%',sm:'70%'}}}>
                            <Typography
                                variant='textBase'
                            >
                                {count == 1 ? 
                                    count + " " + t("pagine.teams.teamfound") : 
                                    count + " " + t("pagine.teams.teamsfound")
                                } 
                            </Typography>
                        </Box>
                        <Pagination 
                            count={Math.trunc(count % rowsPerPage == 0 ? (count / rowsPerPage) : (count / rowsPerPage) + 1)} 
                            page={page} 
                            onChange={handleChangePage}
                            variant="outlined" 
                            siblingCount={0}
                            color="primary"
                        />                         
                        <Box sx={{width:{xs:'100%',md:'70%',sm:'80%',tb:'70%'}}}>
                            <TeamListTable teamsList={listTeams} linkpage={true} />
                        </Box>
                        <Box sx={{margin:'20px 0 !important'}}>
                            <Pagination 
                                count={Math.trunc(count % rowsPerPage == 0 ? (count / rowsPerPage) : (count / rowsPerPage) + 1)} 
                                page={page} 
                                onChange={handleChangePage}
                                variant="outlined" 
                                siblingCount={0}
                                color="primary"
                            />
                        </Box>
                    </Stack>
                    )}
                </Stack>

                {/* Sezione dx */}
                <Stack
                    direction="column"
                    justifyContent={{xs:'center',md:'center',sm:'flex-start',tb:'flex-start'}}
                    alignItems={{xs:'center',md:'center',sm:'flex-start',tb:'flex-start'}}
                    spacing={1}
                    sx={{width:{xs:'100%',md:'30%',sm:'100%',tb:'100%'}}}
                >
                    {/* Social */}
                    <SocialMediaShare 
                        url={window.location.href} 
                        quote={t("social.share.teams")} 
                        hashtag={[t("social.hashtag.calciomercato"),t("social.hashtag.calcio")]}
                        propsadd={{marginBottom:'20px'}}
                    />

                    {/* Banner */}
                    <Box 
                        sx={{
                            paddingLeft:{xs:'0 !important',md:'0 !important',sm:'120px !important',tb:'130px !important'},
                            textAlign:{xs:'center',md:'center',sm:'left',tb:'left'},
                            width:'100%',
                            marginTop:{xs:'20px !important',md:'20px !important',sm:'20px !important'}
                        }}
                    >
                        <Banner type={'300x300_3'} />
                    </Box>

                    {/* Giocatori */}
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            width:{xs:'100%',md:'75%',sm:'70%',tb:'60%'},
                            marginTop:{xs:'50px !important',md:'50px !important',sm:'50px !important'}
                        }}
                    >
                        <Typography variant='titleAside' sx={{fontSize:'21px !important'}}>
                            {parse(t("global.players"))}
                        </Typography>
                        <Box sx={{width:'100%',textAlign:'right',margin:'-20px 0 0 0 !important'}}>
                            <Button 
                                variant='textAction'
                                endIcon={<KeyboardDoubleArrowRightIcon />}
                                href='/players'                            
                            >
                                {t("global.veditutti")}
                            </Button>
                        </Box>
                        {loadingSummary && (
                            <CircularProgress size={44} sx={{color:theme.palette.primary.main,marginTop:'20px !important'}} />
                        )}
                        {!loadingSummary && (
                            <PlayersListTable 
                                players={
                                    playerSummary.filter((p,index) => index >= indexRandom && index <= indexRandom + 6)
                                } 
                                viewTeam={false} 
                                viewRole={false}
                            />
                        )}
                    </Stack>

                    {/* Banner */}
                    <Box 
                        sx={{
                            paddingLeft:{xs:'0 !important',md:'0 !important',sm:'120px !important',tb:'130px !important'},
                            textAlign:{xs:'center',md:'center',sm:'left',tb:'left'},
                            width:'100%',
                            marginTop:'50px !important'
                        }}
                    >
                        <Banner type={'300x300_2'} />
                    </Box>                                        
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Teams;