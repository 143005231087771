import { action, thunk } from 'easy-peasy';

export default{
    viewSite: true,
    isLogged: false,
    showMessageNotLogged: true,
    setShowMessageNotLogged: action((state, payload) => {
        state.showMessageNotLogged = payload;
    }),
    openMenu: false,
    setOpenMenu: action((state,payload) => {
        state.openMenu = payload;
    }),    
    loading: false,
    setLoading: action((state,payload) => {
        state.loading = payload;
    }),
    loadingPage: false,
    setLoadingPage: action((state,payload) => {
        state.loadingPage = payload;
    }),
    loadingDialog: false,
    setLoadingDialog: action((state, payload) => {
        state.loadingDialog = payload;
    }),
    user: null,
    setUser: action((state,payload) => {
        state.user = payload;
        state.user.logged = true;
        state.openMenu = false;
        state.openDialogLogin = false;
    }),
    isRegistred: false,
    resetRegistred: action((state,payload) => {
        state.isRegistred = payload;
        state.errorServiceRegistred = '';
        state.openDialogRegistred = false;
    }),
    setRegistred: action((state,payload) => {
        state.user = payload;
        state.isRegistred = true;
        state.openDialogRegistred = true;
    }),
    errorRegistred: '',
    setErrorRegistred: action((state,payload) => {
        state.errorRegistred = payload;
    }),
    errorServiceRegistred: '',
    setErrorServiceRegistred: action((state, payload) =>{
        state.errorServiceRegistred = payload;        
    }),    
    setErrorFromServiceRegistred: action((state, payload) =>{  
        state.errorServiceRegistred = payload;
        state.openDialogRegistred = true;
    }),
    openDialogLogin: false,
    setOpenDialogLogin: action((state,payload) => {
        state.openDialogLogin = payload;
    }),
    openDialogRegistred: false,
    setOpenDialogRegistred: action((state,payload) => {
        state.openDialogRegistred = payload;
    }),
    errorLogin: '',
    setErrorLogin: action((state,payload) => {
        state.errorLogin = payload;
//        state.openMenu = true;
//        state.openDialogLogin = true;
    }),
    messageVerified: '',
    setMessageVerified: action((state,payload) => {
        state.messageVerified = payload;
    }),
    setVerified: action((state,payload) => {
        const {email, name, verified, message} = payload;
        const userVerified = {
            id: null,
            email: email,
            password: null,
            name: name,
            token: null,
            verified: verified,
            logged: false
        };
        state.user = userVerified;
        state.messageVerified = message;
    }),    
    resendedEmailConfirm: false,
    setResendedEmailConfirm: action((state, payload) => {
        const {resended, message} = payload;
        state.resendedEmailConfirm = resended;
        state.messageResendedEmailConfirm = message;
    }),
    messageResendedEmailConfirm: '',
    setMessageResendedEmailConfirm: action((state, payload) => {
        state.messageResendedEmailConfirm = payload;
    }),
    passwordRecovered: false,
    setPasswordRecovered: action((state, payload) => {
        state.passwordRecovered = payload;
    }),
    messageResetPassword:'',
    setMessageResetPassword: action((state, payload) => {
        state.messageResetPassword = payload;
    }),
    messageUpdateUser:'',
    setMessageUpdateUser: action((state, payload) => {
        state.messageUpdateUser = payload;
    }),   
    logout: action((state, payload) => {
        if(state.user !== null)
            state.user.logged = false;        
        state.openMenu = false;        
    }),
    relogin: action((state, payload) => {
        if(state.user !== null)
            state.user.logged = true;
        state.openMenu = false;        
    }),
    login: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        const { email, password } = payload;
        actions.setLoading(true);                
        /*
            Se il token è già impostato e email e password non sono cambiati 
            non richiamo il servizio per evitare di generare un altro token
        */
        const currentUser = getStoreState().user.user;
        //Se i dati inseriti corrispondono a quelli inseriti loggo
        //Non controllo la password perchè potrebbe essere cambiata
        if( currentUser !== null && currentUser.token !== '' && currentUser.verified &&
            currentUser.email === email && currentUser.password === password)
        {
            actions.relogin(); //Inserita la funzione per far aggiornare lo stato dell'applicazione
            actions.setLoading(false);
            actions.setErrorLogin('');
            actions.setOpenDialogLogin(false);
            return;
        }       
        services.auth.login(payload)
            .then(data => {
                if(data.status){
                    //Aggiono l'utente connesso solo se il login ha successo                   
                    const userLogged = {
                        id: data.it,
                        email: payload.email,
                        password: payload.password,
                        name: data.name,
                        token: data.token,
                        verified: data.email_verified_at !== '',
                        logged: data.logged
                    };
                    actions.setUser(userLogged);
                    actions.setErrorLogin('');    
                    actions.setOpenDialogLogin(false); 
                }
                else{                              
                    actions.setErrorLogin(data.message);    
                    actions.setOpenDialogLogin(true);                
                }
            })
            .catch(e => {
                console.log("ERRORE ", e);
            })
            .finally(_ => {                
                actions.setLoading(false);                      
            });
    }),
    register: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        const userRegister = {
            id: '',
            email: payload.email,
            password: payload.password,
            name: payload.name,
            token: '',
            verified: false,
            logged: false
        };
        actions.setLoading(true);
        actions.setOpenDialogRegistred(false);        

        services.auth.register(payload)
            .then(data => {
                if(data.status){
                    userRegister.id = data.id;                    
                    actions.setRegistred(userRegister);
                    actions.setErrorFromServiceRegistred('');
                }
                else
                    actions.setErrorFromServiceRegistred(data.message);
            })
            .catch(e => {
                actions.setErrorFromServiceRegistred('formlogin.incorrectdata');
            })
            .finally(_ => {
                actions.setLoading(false);
                actions.setOpenDialogRegistred(true);
            });
    }),
    updateUser: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        const userUpdated = {
            id: payload.id,
            email: payload.email,
            password: payload.password,
            name: payload.name,
            token: payload.token,
            verified: payload.verified,
            logged: true
        };
        const bodyService = {
            email: payload.email,
            name: payload.name
        };
        actions.setLoading(true);
        services.auth.updateUser(bodyService)
            .then(data => {      
                if(data.status){         
                    actions.setUser(userUpdated);           
                    actions.setMessageUpdateUser("dataupdated");
                }
                else
                    actions.setMessageUpdateUser("dataupdatederror");
            })
            .catch(e => {
                actions.setMessageUpdateUser('dataupdatederror');
            })
            .finally(_ => {
                actions.setLoading(false);
            });
    }),
    confirmEmail: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoadingPage(true);
        /*
        if(getStoreState().user.user == null){
            actions.setMessageVerified('global.pagenotfound');
            actions.setLoadingPage(false);
            return;
        }
        */
        services.auth.confirmEmail(payload)
            .then(data => {
                if(data.status){                    
                    const verified = data.email_verified_at !== '';                    
                    actions.setVerified(
                        {
                            "email": data.email,
                            "name": data.name,
                            "verified":verified, 
                            "message":"formlogin.accountverified"
                        }
                    );
                }
                //else
                 //   actions.setVerified({"verified":false, "message":data.message});
            })
            .catch(e => {
                actions.setVerified({"verified":false, "message":'formlogin.verifyusererror',"email":null,"name":null});
            })
            .finally(_ => {
                actions.setLoadingPage(false);
            });
    }),
    resendEmailConfirm:thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoadingDialog(true);
        if(getStoreState().user.user == null){
            actions.setResendedEmailConfirm({resended: false, message: 'formlogin.resendemailconfirmerror'});            
            actions.setLoadingDialog(false);
            return;
        }
        services.auth.resendEmailConfirm(payload)
            .then(data => {
                if(data.status){                    
                    actions.setResendedEmailConfirm({"resended":true, "message":data.message});                    
                }
                else
                    actions.setResendedEmailConfirm({"resended":false, "message":data.message}); 
            })
            .catch(e => {
                actions.setResendedEmailConfirm({"resended":false, "message":"resendemailconfirmerror"});
            })
            .finally(_ => {
                actions.setLoadingDialog(false);
            });
    }),
    recoverPassword: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoading(true);
        services.auth.recoverPassword(payload)
        .then(data => {
            if(data.status){
                actions.setPasswordRecovered(true);
            }
        })
        .catch(e => {
            actions.setPasswordRecovered(false);
        })
        .finally(_ => {
            actions.setLoading(false);
        });
    }),
    changePassword: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        const {email, password, token, lang} = payload;
        actions.setLoading(true);
        services.auth.changePassword(payload)
        .then(data => {            
                actions.setMessageResetPassword(data.message);
                //Se il cambio password ha avuto successo aggiorno l'utenza in cache
                const currentUser = getStoreState().user.user;
                /*if(currentUser != null && currentUser.email == email && currentUser.token == token){
                    currentUser.password = password;
                    actions.setUser(currentUser);
                }*/
        })
        .catch(e => {
            
        })
        .finally(_ => {
            actions.setLoading(false);
        });
    })
};