import login from './login';
import register from './register';
import confirmEmail from './confirmEmail';
import resendEmailConfirm from './resendEmailConfirm';
import recoverPassword from './recoverPassword';
import changePassword from './changePassword';
import updateUser from './updateUser';

export default {
    login,
    register,
    confirmEmail,
    resendEmailConfirm,
    recoverPassword,
    changePassword,
    updateUser
};