import React, {Fragment, useEffect, useState, useRef} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import {Link, Stack, Typography, FormControl, FormHelperText, CircularProgress, InputAdornment, InputLabel} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LoginIcon from '@mui/icons-material/Login';
import DialogLogin from './DialogLogin';

const FormLogin = props => {
    const { onClose } = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const inputRef = useRef();
    const loading = useStoreState(state => state.user.loading);
    const setOpenMenu = useStoreActions(dispatch => dispatch.user.setOpenMenu);
    const errorLogin = useStoreState(state => state.user.errorLogin);    
    const setErrorLogin = useStoreActions(dispatch => dispatch.user.setErrorLogin);
    const login = useStoreActions(dispatch => dispatch.user.login);
    const passwordRecovered = useStoreState(state => state.user.passwordRecovered);
    const setPasswordRecovered = useStoreActions(dispatch => dispatch.user.setPasswordRecovered);
    const recoverPassword = useStoreActions(dispatch => dispatch.user.recoverPassword);
    const openDialogLogin = useStoreState(state => state.user.openDialogLogin);    
    const setOpenDialogLogin = useStoreActions(dispatch => dispatch.user.setOpenDialogLogin);    
    const headerLogin = parse(t("formlogin.headerlogin"));
    const forgotPassword = parse(t("formlogin.forgotpassword"));
    const makeLogin = "<< " + parse(t("formlogin.login"));
    const headerRecoveryPassword = parse(t("formlogin.headerrecoverypassword"));
    const fieldRequired = parse(t("formlogin.fieldrequired"));
    const loginMessage = errorLogin === '' ? headerLogin : parse(t("formlogin." + errorLogin));
    const colorLoginMessage = errorLogin === '' ? '#000' : theme.palette.error.main;    
    const [recoveryPassword,setRecoveryPassword] = useState(false);
    
    const[values,setValues] = useState({
        email:'',
        errorEmail:'',
        password:'', 
        errorPassword:'',
        showPassword: false
    });
        
    useEffect(() => {
        setErrorLogin('');
        setOpenDialogLogin(false);
    },[]);

    //Pressione ENTER per login
    useEffect(() => {
		const onKeyDown = event => {
			if (event.key === 'Enter') {
				if (inputRef.current) inputRef.current.click(event);
			}
		};
		document.addEventListener('keydown', onKeyDown);
	}, []);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });      
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,            
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {        
        event.preventDefault();      
        //setErrorLogin('');                  
        values.errorEmail = '';
        values.errorPassword = '';
        setValues({
            ...values,
            errorEmail: values.email === '' ? fieldRequired : '',
            errorPassword: values.password === '' ? fieldRequired : ''
        });
        if(values.errorEmail === '' && values.errorPassword === ''
            && values.email !== '' && values.password !== '')
        {
            login({
                email: values.email,
                password: values.password
            });
        }
    };

    const handleRecoveryPassword = _ => {
        values.errorEmail = '';
        setRecoveryPassword(!recoveryPassword);
        setPasswordRecovered(false);
    };

    const handleSubmitPasswordRecover = (event) => {
        event.preventDefault(); 
        values.errorEmail = '';
        setValues({
            ...values,
            errorEmail: values.email === '' ? fieldRequired : '',           
        });
        if(values.errorEmail === '' && values.email)
        {
            recoverPassword({
                email: values.email,
                lang: i18n.language
            });
        }
    };

    const handleCloseDialogLogin = () => {
        //setOpenMenu(false);
        setErrorLogin('');        
        setOpenDialogLogin(false);
    };

    return(
    <Fragment>
        <Stack>
            <Box sx={{borderBottom:'1px solid #e5e5e5',textAlign:'center',padding:'12px 0',marginBottom:'15px'}}>
                <Typography variant='textBase' sx={{fontSize:'14px'}} color={colorLoginMessage}>
                    {!recoveryPassword ? loginMessage : headerRecoveryPassword}
                </Typography>
            </Box>
            <Stack sx={{padding:'20px 30px'}}>
                <FormControl variant='standard'>
                    <InputLabel htmlFor='formlogin-email' sx={{fontSize:'18px'}}>Email</InputLabel>
                    <Input
                        id="formlogin-email"
                        aria-describedby="formlogin-email-error"
                        value={values.email}
                        onChange={handleChange('email')}
                        startAdornment={
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        }
                        variant='formLogin'    
                    />
                    <FormHelperText 
                        id="formlogin-email-error"
                        variant='formValidate'   
                    >
                        {values.errorEmail}
                    </FormHelperText>
                </FormControl>
                {!recoveryPassword && (
                    <FormControl variant='standard' sx={{marginTop:'10px',color:`${theme.palette.error.main}`}}>
                        <InputLabel htmlFor='formlogin-password' sx={{fontSize:'18px'}}>Password</InputLabel>
                        <Input
                            id="formlogin-password"
                            aria-describedby="formlogin-password-error"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            error={false}
                            startAdornment={
                                <InputAdornment position="start">
                                    <VpnKeyIcon />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            variant='formLogin'
                        />
                        <FormHelperText 
                            id="formlogin-password-error"
                            variant='formValidate'
                        >
                            {values.errorPassword}
                        </FormHelperText>
                    </FormControl>
                )}
                {!recoveryPassword && (
                    <Box sx={{textAlign:'center',marginTop:'10px'}}>
                        <Link 
                            href='#' 
                            onClick={handleRecoveryPassword}
                            variant='linkBase' 
                            sx={{textAlign:'center',lineHeight:'1em',fontSize:'1em',fontWeight:'400'}}
                        >
                            {forgotPassword}
                        </Link>
                    </Box>
                )}
                {recoveryPassword && (
                    <Stack spacing={1}>
                        <Typography 
                            variant='textBase' 
                            sx={{fontSize:'14px',marginTop:'10px',textAlign:'center'}} 
                            color='#888'
                        >
                            { 
                                (recoveryPassword && !passwordRecovered) ? 
                                    parse(t("formlogin.recoverypasswordtext")) :
                                (recoveryPassword && passwordRecovered) ? 
                                    parse(t("formlogin.recoverypassworddone")) : ''
                            }                        
                        </Typography>
                        <Link 
                            href='#' 
                            onClick={handleRecoveryPassword}
                            variant='linkBase' 
                            sx={{textAlign:'center',lineHeight:'1em',fontSize:'0.9em'}}
                        >
                            {makeLogin}
                        </Link>
                    </Stack>
                )}
            </Stack>
            <Button 
                ref={inputRef}
                variant='iconFull' 
                onClick={!recoveryPassword ? handleSubmit : handleSubmitPasswordRecover} 
                disabled={loading} 
            >
                <Stack 
                    direction="row" 
                    spacing={0}
                    alignItems="center" 
                    justifyContent="center"                   
                >
                    <Box sx={{
                        padding:'0 20px',
                        lineHeight:'60px',
                        position:'absolute',
                        left:'0',
                        backgroundColor:`${theme.palette.custom.yellowDark}`}}
                    >
                        <LoginIcon />
                    </Box>
                    {loading && (<CircularProgress sx={{color:'#fff'}} />)}
                    {!loading && (
                    <Typography variant='text' sx={{fontWeight:'600'}}>
                        {!recoveryPassword ? parse(t("formlogin.login")) : parse(t("formlogin.recover"))}
                    </Typography>
                    )}
                </Stack>    
            </Button>        
        </Stack>
        {openDialogLogin && <DialogLogin open={openDialogLogin} onClose={handleCloseDialogLogin} />}
    </Fragment>
    );
};

export default FormLogin;