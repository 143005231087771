import React, {useState} from 'react';
import { useStoreState } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { Stack, Box, Button, Typography, CircularProgress, LinearProgress, linearProgressClasses } from '@mui/material';
import MessageLogin from '../MessageLogin';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import PopoverDesc from '../PopoverDesc';

const LinearProgressValue = props => {
    const {LinearProgressProps, value, max, min} = props;
    const theme = useTheme();
    const user = useStoreState(state => state.user.user);
    const userLogged = user == null ? false : user !== null && user.logged && user.token !== '';

    return (
        <Stack 
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            sx={{marginTop:'10px',width:'100%'}}
        > 
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress 
                    variant="determinate" 
                    value={parseFloat(value)} 
                    {...LinearProgressProps} 
                    sx={{
                        height:'10px',
                        borderRadius:'5px',
                        [`&.${linearProgressClasses.colorPrimary}`]:{
                            backgroundColor:theme.palette.custom.grey
                        }
                    }}
                />
            </Box> 
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
                sx={{width:'100%'}}
            >
                <Typography
                    variant='textSmall'
                >
                    {min}
                </Typography>
                <Typography
                    variant='textSmall'
                >
                    {max}
                </Typography>
            </Stack>
        </Stack>
    );
};

const CoefficientBox = props => {
    const {userLogged} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const loadingStats = useStoreState(state => state.players.getPlayer.loadingStats);
    const player = useStoreState(state => state.players.getPlayer.playerDetailStats);
    const playersLimits = useStoreState(state => state.players.playerSummary.playersLimits);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const valueLimitsMax = playersLimits.filter(val => val.type === "max")[0];
    const valueLimitsMin = playersLimits.filter(val => val.type === "min")[0];
    if(!player)
        return;
    
    const valueList = [
        {
            "text":"score",
            "value":userLogged && player && player.score_value ? player.score_value : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.score_value : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.score_value : 0
        },
        {
            "text":"pass",
            "value":userLogged && player && player.pass_value ? player.pass_value : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.pass_value : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.pass_value : 0
        },
        {
            "text":"defence",
            "value":userLogged && player && player.defence_value ? player.defence_value : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.defence_value : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.defence_value : 0
        },
        {
            "text":"save",
            "value":userLogged && player && player.save_value ? player.save_value : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.save_value : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.save_value : 0

        }
    ];
    const valueList1 = [
        {
            "text":"score",
            "value":userLogged && player && player.score_value ? player.score_value : 0,            
            "max":playersLimits.length > 0 ? valueLimitsMax.score_value : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.score_value : 0,
            "perc":playersLimits.length > 0 ? 
                (player.score_value - valueLimitsMin.score_value) / (valueLimitsMax.score_value - valueLimitsMin.score_value) : '-' 
        },
        {
            "text":"pass",
            "value":userLogged && player && player.pass_value ? player.pass_value : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.pass_value : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.pass_value : 0,
            "perc":playersLimits.length > 0 ? 
                (player.pass_value - valueLimitsMin.pass_value) / (valueLimitsMax.pass_value - valueLimitsMin.pass_value) : '-' 
        }
    ];
    const valueList2 = [
        {
            "text":"defence",
            "value":userLogged && player && player.defence_value ? player.defence_value : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.defence_value : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.defence_value : 0,
            "perc":playersLimits.length > 0 ? 
                (player.defence_value - valueLimitsMin.defence_value) / (valueLimitsMax.defence_value - valueLimitsMin.defence_value) : '-' 
        },
        {
            "text":"save",
            "value":userLogged && player && player.save_value ? player.save_value : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.save_value : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.save_value : 0,
            "perc":playersLimits.length > 0 ? 
                (player.save_value - valueLimitsMin.save_value) / (valueLimitsMax.save_value - valueLimitsMin.save_value) : '-' 
        }
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
  
    return(
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
        >
            <Stack direction="row" spacing={0.5} justifyContent="flex-start" alignItems="center">
                <BubbleChartIcon sx={{color:theme.palette.primary.main,fontSize:'30px'}} />
                <Box>
                    <Typography 
                        variant='titleSection'
                        sx={{
                            fontSize:{xs:'22px',md:'22px',sm:'22px'},
                            color:theme.palette.custom.black,
                            fontWeight:'600'                   
                        }}
                    >
                        {t("global.coefficients")}
                    </Typography>                    
                </Box>
            </Stack>
            <Box>
                <Button 
                    variant='textLink'
                    onClick={handleClick}
                    sx={{fontSize:{xs:'16px',md:'15px',sm:'15px'}}}
                >
                    {parse(t("global.coefficientswhat"))}
                </Button>
                <PopoverDesc 
                    open={open} 
                    anchorEl={anchorEl} 
                    handleClose={handleClose} 
                    icon={<BubbleChartIcon sx={{fontSize:'30px !important'}} />}
                    title={"coefficients"}
                    what={"coefficientswhat"}
                    desc={"coefficientsdesc"}
                />
            </Box>
            {!userLogged && (<MessageLogin data={t("messages.values")} color={theme.palette.custom.black} />)}
            {loadingStats && (
                <Box sx={{margin:'30px auto 20px',textAlign:'center'}}>
                    <CircularProgress 
                        size="80px"
                        sx={{color:theme.palette.primary.main}} 
                    />
                </Box>
            )}
            {!loadingStats && (
                <Stack
                    direction={{xs:'column',md:'row',sm:'row'}}
                    justifyContent="center"
                    alignItems="center"
                    spacing={{xs:3,md:2,sm:2}}
                    sx={{
                        marginTop:{xs:'8px',md:'10px',sm:'10px'},
                        width:{xs:'100%',md:'100%',sm:'100%'}
                    }}
                >
                    <Stack 
                        direction="row" 
                        justifyContent="space-between" 
                        alignItems="center"                         
                        spacing={2}
                        sx={{width:{xs:'100%',md:'50%',sm:'50%'}}}
                    >
                        {valueList1.map((val, index) => (
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                key={val.text}
                                sx={{width:'50%'}}
                            >
                                <Typography 
                                    variant='textBase'
                                    sx={{
                                        color:'#333',
                                        fontWeight:'500',
                                        fontSize:{xs:'15px',md:'15px',sm:'15px'}                                       
                                    }}
                                >
                                    {t("coefficients." + val.text)}
                                </Typography>   
                                <Typography
                                    variant='titleSection' 
                                    sx={{                                    
                                        fontSize:{xs:'50px',md:'50px',sm:'50px'},
                                        marginBottom: '-5px',
                                        color: !userLogged ? theme.palette.custom.grey :
                                            val.value > 0 ? theme.palette.custom.green :
                                            val.value < 0 ? theme.palette.custom.red : theme.palette.custom.black
                                    }}
                                >
                                    {val.value != 0 ? val.value : 0}
                                </Typography>       
                                {userLogged && (
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={0}
                                    sx={{width:'100%'}}
                                >
                                    <LinearProgressValue                                    
                                        value={val.value != 0 ? parseFloat(val.value * 100 / val.max).toFixed(2) : 0}
                                        max={val.max}
                                        min={val.min}
                                    />
                                    <Typography 
                                        variant='textBase'
                                        sx={{
                                            color:theme.palette.primary.main,
                                            fontWeight:'600',
                                            fontSize:{xs:'15px',md:'15px',sm:'15px'},
                                            marginTop:'-18px'
                                               
                                        }}
                                    >
                                        {val.perc !== '-' ? '(' + parseFloat(val.perc * 100).toFixed(0) + '%)' : val.perc}
                                    </Typography>
                                </Stack>
                                )}                    
                            </Stack>
                        ))}
                    </Stack>
                    <Stack 
                       direction="row" 
                       justifyContent="space-between" 
                       alignItems="center"                         
                       spacing={2}
                       sx={{width:{xs:'100%',md:'50%',sm:'50%'}}}
                    >
                        {valueList2.map((val, index) => (
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                key={val.text}
                                sx={{width:'50%'}}
                            >
                                <Typography 
                                    variant='textBase'
                                    sx={{
                                        color:'#333',
                                        fontWeight:'500',
                                        fontSize:{xs:'15px',md:'15px',sm:'15px'}
                                    }}
                                >
                                    {t("coefficients." + val.text)}
                                </Typography>
                                <Typography
                                    variant='titleSection' 
                                    sx={{                                    
                                        fontSize:{xs:'50px',md:'50px',sm:'50px'},
                                        marginBottom: '-5px',
                                        color: !userLogged ? theme.palette.custom.grey :
                                            val.value > 0 ? theme.palette.custom.green :
                                            val.value < 0 ? theme.palette.custom.red : theme.palette.custom.black
                                    }}
                                >
                                    {val.value != 0 ? val.value : 0}
                                </Typography>        
                                {userLogged && (  
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={0}
                                        sx={{width:'100%'}}
                                    >                                                          
                                        <LinearProgressValue                                    
                                            value={val.value != 0 ? val.value : 0}
                                            max={val.max}
                                            min={val.min}
                                        />
                                        <Typography 
                                            variant='textBase'
                                            sx={{
                                                color:theme.palette.primary.main,
                                                fontWeight:'600',
                                                fontSize:{xs:'15px',md:'15px',sm:'15px'},
                                                marginTop:'-18px'
                                                
                                            }}
                                        >
                                            {val.perc !== '-' ? '(' + parseFloat(val.perc * 100).toFixed(0) + '%)' : val.perc}
                                        </Typography>
                                    </Stack>
                                )}
                            </Stack>
                        ))}
                    </Stack>                                 
                </Stack>
            )}
        </Stack>
        
    );
};

export default CoefficientBox;