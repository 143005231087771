import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Stack, Typography, Avatar, TextField, Box, Button, CircularProgress } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PageNotFound from '../../components/PageNotFound';

const Account = props => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);    
    const loading = useStoreState(state => state.user.loading);  
    const messageUpdateUser = useStoreState(state => state.user.messageUpdateUser);
    const setMessageUpdateUser = useStoreActions(dispatch => dispatch.user.setMessageUpdateUser);
    const updateUser = useStoreActions(dispatch => dispatch.user.updateUser);
    const recoverPassword = useStoreActions(dispatch => dispatch.user.recoverPassword);
    const setPasswordRecovered = useStoreActions(dispatch => dispatch.user.setPasswordRecovered);
    const passwordRecovered = useStoreState(state => state.user.passwordRecovered);
    const userLogged = user == null ? false : user !== null  && user.logged && user.token !== '';
    const [nome,setNome] = useState(user ? user.name : '');    

    useEffect(() => {
        setMessageUpdateUser('');
        setPasswordRecovered(false);
    },[]);

    const handleChangeName = (event) => {
        setNome(event.target.value);        
    };

    const handleClick = (event) => {   
        setMessageUpdateUser('');
        //setNome(nome);
        updateUser({
            id: user.id,
            email: user.email,
            password: user.password,
            name: nome,
            token: user.token,
            verified: user.verified
        });        
    };

    const handleSubmitPasswordRecover = (event) => {
        event.preventDefault(); 
        recoverPassword({
            email: user.email,
            lang: i18n.language
        });
    };

    return(
        <Stack sx={{width:'100%',minHeight:'80vh'}}>
        {!userLogged && (<PageNotFound />)}
        {userLogged && (
        <Stack 
            direction="column" 
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            sx={{width:'100%'}}
        >
             {/** Titolo pagina */}
             <Stack 
                direction="row" 
                justifyContent="flex-start" 
                alignItems="center" 
                spacing={1}
                sx={{
                    width:'100%',
                    backgroundColor:theme.palette.custom.black,
                    color:theme.palette.custom.white,
                    padding:{xs:'10px 20px', md:'10px 190px',sm:'10px 40px',tb:'10px 40px'}
                }}
            >
                <Avatar sx={{backgroundColor:theme.palette.primary.main}} ><PersonIcon /></Avatar>
                <Typography variant='pageTitle'>Account</Typography>
            </Stack>   
            <Stack 
                direction="column" 
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
                sx={{width:'100%',padding:{xs:'20px',md:'30px 190px',sm:'20px 40px'}}}
            >               
                <Stack 
                    direction="column" 
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0}
                    sx={{marginTop:{xs:'20px',md:'50px 190px',sm:'20px 40px'}}}
                >
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <AlternateEmailIcon sx={{color:theme.palette.primary.main,marginRight:'5px'}} />
                        <TextField 
                            id="account-email" 
                            label="email" 
                            variant='standard' 
                            value={user.email}
                            disabled={true}
                            sx={{minWidth:'300px'}}
                        />
                    </Box>
                    <Box sx={{display:'flex',alignItems:nome === '' ? 'center' : 'flex-end',marginTop:'20px'}}>
                        <AccountCircle sx={{color:theme.palette.primary.main,marginRight:'5px'}} />
                        <TextField 
                            id="account-name" 
                            label={t("global.name")}
                            variant='standard' 
                            value={nome}
                            onChange={handleChangeName}
                            error={nome === ''}
                            helperText={nome === '' ? t("formlogin.fieldrequired"): ''}
                            sx={{minWidth:'300px'}}
                        />
                    </Box>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{marginTop:'30px'}}>
                        {user.verified && (<HowToRegIcon sx={{color:theme.palette.custom.green}} />)}
                        {!user.verified && (<PersonOffIcon sx={{color:theme.palette.custom.red}} />)}
                        <Typography variant='text' sx={{fontSize:'16px'}}>
                            {user.verified ? t("global.accountverified") : t("global.accountunverified")}
                        </Typography>                        
                    </Stack>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{marginTop:'30px'}}>
                        <Button
                            variant='secondary'
                            startIcon={loading ? <CircularProgress size={20} /> : <VpnKeyIcon />}
                            onClick={handleSubmitPasswordRecover}
                        >{t('formlogin.headerrecoverypassword')}</Button>
                    </Stack>
                    <Button
                        variant='action'
                        sx={{marginTop:'30px !important'}}
                        endIcon={loading ? <CircularProgress size={20} /> : <DoubleArrowIcon />}
                        disabled={nome === '' || nome === user.name}
                        onClick={handleClick}
                    >
                        {t("global.save")}
                    </Button>
                    <Typography variant='text' sx={{fontSize:'16px',marginTop:'30px'}}>
                        {
                            messageUpdateUser !== '' ? t("global." + messageUpdateUser) : 
                                passwordRecovered ? t("formlogin.recoverypasswordfromaccount") : ''
                        }
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
        )}
        </Stack>
    );
};

export default Account;