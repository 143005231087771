import { action, thunk } from 'easy-peasy';
import utils from '../utils';

export default {
    players: [],
    playersLight: [],
    meta: null,
    links: null,
    loading: false,
    setLoading: action((state,payload) =>{        
        state.loading = payload;
    }),
    playersLimits: [],
    savePlayersLimits: action((state, payload) => {
        state.playersLimits = [];
        payload['limit'].map((limite) => {
            state.playersLimits.push(utils.decryptResponse(limite));
        });
    }),
    savePlayerSummary: action((state, payload) => {
        state.meta = payload['meta'];
        state.links = payload['links'];
        state.players = [];
        payload['players'].map((player) => {
            state.players.push(utils.decryptResponse(player));
        });
    }),    
    savePlayerLight: action((state, payload) => {
        state.meta = payload['meta'];
        state.links = payload['links'];
        state.playersLight = [];
        payload['players'].map((player) => {
            state.playersLight.push(utils.decryptResponse(player));
        });
    }),  
    retrievePlayerSummary: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;

        services.players.playerSummary(payload)
            .then(data => {
                actions.savePlayerLight(data)
            })
            .catch(e => {
                console.log("ERRORE ", e);
            })
            .finally(_ => {
                
            });
    }),
    retrieveAllPlayerSummary: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoading(true);
        services.players.getAllPlayersSummary(payload)
            .then(data => {
                actions.savePlayerSummary(data);                
            })
            .catch(e => {
                console.log("ERRORE ", e);
            })
            .finally(_ => {
                actions.setLoading(false);
            });
    }),
    retrievePlayersLimit: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoading(true);
        services.players.getPlayersLimits(payload)
            .then(data => {
                actions.savePlayersLimits(data);                
            })
            .catch(e => {
                console.log("ERRORE ", e);
            })
            .finally(_ => {
                actions.setLoading(false);
            });
    })
};