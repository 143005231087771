import React, {useEffect, useState} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import {Stack, Box, Typography, IconButton, Avatar, Button, FormGroup, FormControlLabel, Checkbox} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';

const MessageHeaderLogin = props => {
    const {onClose} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const setShowMessageNotLogged = useStoreActions(dispatch => dispatch.user.setShowMessageNotLogged);
    const bgImg1 = process.env.REACT_APP_PUBLIC_URL + "/assets/images/balls_bg.jpg";
    const [checked,setChecked] = useState(false);
    const list = parse(t("messages.notloggedlist")).split(';');

    const handleChange = (event) => {
        setChecked(!checked);
        //setShowMessageNotLogged(false);
    };

    const handleClose = (event) => {
        setShowMessageNotLogged(!checked);
        onClose();
    };

    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{
                width:{xs:'90%',md:'50%',sm:'80%',lg:'50%'},
                position:'absolute',
                top:'50%',
                left:'50%',
                transform: 'translate(-50%, -50%)',
                boxShadow: 24,
                padding:{xs:'10px',md:'20px',sm:'10px',lg:'30px'},
                backgroundColor:theme.palette.custom.body                
            }}
        >
            <Box 
                sx={{
                    width:'100%',
                    textAlign:'right',
                    marginBottom:{xs:'0px !important',md:'-10px !important',sm:'-4px !important',lg:'-10px !important'}
                }}
            >
                <IconButton 
                    onClick={handleClose} 
                    sx={{
                        backgroundColor:theme.palette.primary.main,
                        color:theme.palette.custom.white,
                        padding:'4px',
                        "&:hover":{
                            backgroundColor:theme.palette.secondary.main                            
                        }
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>            
            <Typography 
                variant='headerSection' sx={{lineHeight:'1.1em',textAlign:'center',fontSize:{xs:'20px',md:'22px',sm:'22px',lg:'24px'}}}
            >
                {parse(t("messages.notloggedtitle"))}
            </Typography>                
            <Stack
                direction={{xs:'column',md:'row',sm:'column',lg:'row'}}
                justifyContent="space-between"
                alignItems={{xs:'flex-start',md:'center',sm:'flex-start',lg:'center'}}
                spacing={0}
                sx={{width:'100%',marginTop:{xs:'15px !important',md:'0',sm:'15px !important',lg:'20px !important'}}}
            >
                <Stack 
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0.5}
                >                    
                    {list.map((text,index) => (
                        <Stack 
                            key={index}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={0.5}
                        >
                            <CheckIcon sx={{color:theme.palette.custom.green}} />
                            <Typography variant='textSmall'>{text}</Typography>
                        </Stack>
                    ))}
                </Stack>
                <Box 
                    component="img" 
                    src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/playerstar_valore_calciatori.jpg"}
                    sx={{display:{xs:'none',md:'inherit',sm:'none',lg:'inherit'},maxWidth:'240px'}}
                />                
            </Stack>
            <Stack 
                direction="row" 
                justifyContent="flex-start" 
                alignItems="center" 
                spacing={2}
                sx={{
                    marginTop:'20px !important',
                    padding:'10px',
                    width:'100%',
                    borderWidth:'1px 0 0 0',
                    borderStyle:'solid',
                    borderColor:theme.palette.primary.main
                }}
            >
                <Avatar><PersonIcon /></Avatar>
                <Typography
                    variant='textRound'
                    sx={{fontWeight:'400',fontSize:'16px'}}
                >
                    {parse(t("messages.infovalueviewhow"))}
                </Typography>
            </Stack> 
            <Box 
                sx={{
                    width:'100%',
                    marginTop:'30px',
                    paddingTop:'20px',
                    textAlign:'center',
                    borderWidth:'1px 0 0 0',
                    borderStyle:'solid',
                    borderColor:theme.palette.primary.main
                }}
                >
                <Button variant='action' onClick={handleClose}>{t("global.understood")}</Button>
            </Box>
            <FormGroup sx={{marginTop:'20px'}}>
                <FormControlLabel
                    control={<Checkbox onChange={handleChange} />}
                    label={
                        <Typography variant='textSmall' sx={{fontSize:{xs:'14px',md:'14px',sm:'14px'}}}>
                            {t("messages.dontshowanymore")}
                        </Typography>
                    }
                />
            </FormGroup>
        </Stack>
    );
};

export default MessageHeaderLogin;