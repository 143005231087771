import React, {useEffect, useState, Fragment} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { CircularProgress, Typography, Stack, Box, Button, Modal } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PlayerInfo from './PlayerInfo';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import FieldPosition from '../../components/FieldPosition';
import PlayerTrand from '../../components/PlayerTrand/index.js';
import CoefficientBox from '../../components/CoefficientBox/index.js';
import PlayerIncidence from '../../components/PlayerIncidence';
import PlayerDeviation from '../../components/PlayerDeviation';
import PlayerCalcData from '../../components/PlayerCalcData';
import PlayerOverview from '../../components/PlayerOverview';
import PlayerStatistics from '../../components/PlayerStatistics';
import PlayerPhoto from './PlayerPhoto';
import PlayerIcons from './PlayerIcons';
import PlayerValue from './PlayerValue';
import PlayersListTable from '../../components/PlayersListTable.js';
import TeamListTable from '../../components/TeamListTable.js';
import SkeletonProfile from './SkeletonProfile';
import getRuolo from '../../components/PlayerRole/getRuolo';
import getColor from '../../components/PlayerRole/getColor';
import deKriptPlayerId from '../../components/Utility/deKriptPlayerId';
import Banner from '../../components/Banner';
import SocialMediaShare from '../../components/SocialMediaShare';
import MessageHeaderLogin from '../../components/MessageHeaderLogin/index.js';

const PlayerProfile = props => {
    const params = useParams();
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);
    const userLogged = user == null ? false : user !== null && user.logged && user.token !== '';
    const showMessageNotLogged = useStoreState(state => state.user.showMessageNotLogged); 
    const loading = useStoreState(state => state.players.getPlayer.loading);
    const playerInfoBase = useStoreState(state => state.players.getPlayer.playerInfoBase);    
    const playerTrand = useStoreState(state => state.players.getPlayer.playerTrand);
    const retrievePlayerBaseInfo = useStoreActions(dispatch => dispatch.players.getPlayer.retrievePlayerBaseInfo);
    const retrievePlayerStatsInfo = useStoreActions(dispatch => dispatch.players.getPlayer.retrievePlayerDetailStats);   
    const retrievePlayerCalcData = useStoreActions(dispatch => dispatch.players.getPlayerCalcData.retrievePlayerCalcData);
    const retrievePlayerOverview = useStoreActions(dispatch => dispatch.players.getPlayerOverview.retrievePlayerOverview);
    const retrievePlayerTrand = useStoreActions(dispatch => dispatch.players.getPlayer.retrievePlayerTrand);
    const playersLimits = useStoreState(state => state.players.playerSummary.playersLimits);
    const retrievePlayersLimit = useStoreActions(dispatch => dispatch.players.playerSummary.retrievePlayersLimit);
    const loadingFindPlayers = useStoreState(state => state.players.findPlayer.loading);  
    const playersByTeam = useStoreState(state => state.players.findPlayer.players);
    const findPlayersByTeam = useStoreActions(dispatch => dispatch.players.findPlayer.findPlayersByTeam);
    const loadingTeams = useStoreState(state => state.teams.getTeams.loading);
    const allTeams = useStoreState(state => state.teams.getTeams.teams);
    const retrieveAllTeams = useStoreActions(dispatch => dispatch.teams.getTeams.retrieveAllTeams);
    const playerId = deKriptPlayerId(params.playerId);
    const indexRandom = Math.floor(Math.random() * 14);
    const [color,setColor] = useState(null);
    const [ruolo,setRuolo] = useState(null);
    const [listTeams,setListTeams] = useState([]);
    const [openModal, setOpenModal] = useState(!userLogged);
    const [points, setPoints] = useState("-");
    const dicFieldPosition = t("global.playerpositions").split(" ");
    const othersplayersUrl = playerInfoBase ? 
        '/players?te=' + playerInfoBase.team_name +  '&to=' + playerInfoBase.tournament_name : '/players';
    const otherTeamsUrl = playerInfoBase ? '/teams?to=' + playerInfoBase.tournament_name : '/teams';
    const titleFieldPosition = dicFieldPosition[2] ? dicFieldPosition[0] + " " + 
        "<span className='MainColor'>" + dicFieldPosition[1] + " " + dicFieldPosition[2] + "</span>" :
        dicFieldPosition[0] + " <span className='MainColor'>" + dicFieldPosition[1] + "</span>";

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const extractPoints = (key) => {
        if(!playerTrand)
            return;
        let trand = "";
         Object.keys(playerTrand).map(property => {
            if(property.startsWith("d") && playerTrand[property] != null)
                trand = playerTrand[property];
        });
        if(trand == "-")
            return "-";

        const info = trand.split(";").filter(item => item.startsWith(key + ":"));
        if(info && info.length > 0)
            return parseFloat(info[0].replace(key + ":",""),10);
        else
            return "-";
        
    };

    //Devo sempre caricari i valori limite per averli disponibili nei componenti
    useEffect(() => {        
        if(userLogged)
            retrievePlayersLimit();
    },[]);

    //Quando apro la pagina ricarico tutti i dati del giocatore
    useEffect((_) => {        
        //Dati di base visibili a tutti
        retrievePlayerBaseInfo(playerId);
        //Se non ho già caricato le squadre le carico
        if(!allTeams || allTeams.length === 0)
            retrieveAllTeams();        
        //Se l'utente è connsesso carico anche le altre info
        if(userLogged){
            retrievePlayerStatsInfo(playerId);
            retrievePlayerCalcData(playerId);
            retrievePlayerOverview(playerId);
            retrievePlayerTrand(playerId);
        }
    },[params.playerId]);

    useEffect(() => {        
        //Nel caso in cui il profilo del giocatore non è più attivo ritorno alla pagina dei giocatori
   //     if(playerObsolete)
   //         window.location.replace(redirectUrl);

        if(playerInfoBase && playerInfoBase.player_id === playerId){    
            //setto ruolo
            const ruoloPlayer = getRuolo(playerInfoBase);            
            setRuolo(ruoloPlayer);
            //setto colore
            setColor(getColor(ruoloPlayer));
            //filtro le stesse suqadre del giocatore
            setListTeams(
                allTeams.filter(team => team.tournament_id == playerInfoBase.tournament_id)
                    .filter((t,index) => index >= indexRandom && index <= indexRandom + 4)
            );
            //verifico se ricaricare i giovatori della stessa squadra
            if(!playersByTeam || playersByTeam.length === 0 || playersByTeam[0].team_id !== playerInfoBase.team_id){
                //ricarico i giocatori della stessa squadra
                findPlayersByTeam(playerInfoBase.team_id);
            }
        }        

    }, [playerInfoBase,playerId]);

    useEffect(() => {
        if(playerTrand){        
            setPoints(extractPoints("va"));
        }
    },[playerTrand]);

    return(        
        <Stack
            direction="column"
            justifyContent="center" 
            alignItems="center" 
            spacing={0}
            sx={{width:'100%'}}
        >
            {/* Modale per utente non loggato */}
            <Modal
                open={openModal && showMessageNotLogged}
                onClose={handleCloseModal}
            >
                <Box><MessageHeaderLogin onClose={handleCloseModal} /></Box>
            </Modal>
            
            {loading && (<SkeletonProfile />)}
                       
            {!loading && (     
            <Fragment>
                {/* Icone */}
                <Box
                    sx={{
                        width:'100%',                    
                        padding:{xs:'5px 10px',md:'5px 0 5px 120px',sm:'5px 40px'},
                        backgroundColor:theme.palette.custom.black                                     
                    }}
                >                 
                    <PlayerIcons />                
                </Box>   
                
                {/* Sezione 1 */}
                <Stack 
                    direction={{xs:'column',md:'row',sm:'column'}}
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={2}
                    sx={{
                        width:'100%',                    
                        padding:{xs:'0px 10px 20px 10px',md:'20px 0px 40px 120px',sm:'0px 40px 20px 40px',tb:'20px 90px'},
                        marginTop:{xs:'20px',md:'20px',sm:'20px'}
                    }}
                >                
                    {/* sezione sx */}
                    <Stack 
                        direction="column" 
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            width:{xs:'100%',md:'55%',sm:'100%'},
                            padding:{xs:'0',md:'0 30px 0 0',sm:'0'}
                        }}
                    >
                        {/* Foto, ruolo e valore */}
                        <Stack
                            direction={{xs:'column',md:'row',sm:'row'}}
                            justifyContent="flex-start"
                            alignItems={{xs:'flex-end',md:'center',sm:'center'}}
                            spacing={{xs:2,md:2,sm:2}}
                            sx={{width:'100%'}}
                        >
                            {/* Foto e ruolo */}
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                                sx={{width:{xs:'100%',md:'100%',sm:'100%'}}}
                            >
                                {/* Foto */}                           
                                {playerInfoBase && <PlayerPhoto player={playerInfoBase} color={color} />}

                                {/* Nome e ruolo */}
                                <Stack 
                                    direction="column" 
                                    justifyContent="flex-start" 
                                    alignItems="flex-start" 
                                    spacing={0}
                                    sx={{width:'100%'}}
                                >
                                    {/* Nome */}
                                    <Stack 
                                        direction="column" 
                                        justifyContent="flex-start" 
                                        alignItems="flex-start"
                                        spacing={0}
                                    >                                     
                                        <Typography 
                                            variant='titleSection'
                                            sx={{
                                                fontSize:{xs:'20px',md:'24px',sm:'20px'},
                                                fontWeight:'400',
                                                color:theme.palette.custom.black
                                            }}
                                        >                    
                                            {playerInfoBase ? playerInfoBase.first_name : ''}
                                        </Typography>                                                            
                                        <Typography 
                                            variant='titleSection'
                                            sx={{
                                                fontSize:{xs:'26px',md:'30px',sm:'24px'},
                                                fontWeight:'600',
                                                color:theme.palette.custom.black,
                                                marginBottom:'3px'
                                            }}
                                        >
                                            {playerInfoBase ? playerInfoBase.last_name : ''}
                                        </Typography>
                                    </Stack>
                                    {/* Ruolo */}
                                    <Typography 
                                        variant='titleSection'
                                        sx={{
                                            fontSize:{xs:'18px',md:'18px',sm:'18px'},
                                            fontWeight:'600',
                                            color:theme.palette.custom.blueIcon
                                        }}
                                    >  
                                        {playerInfoBase ? playerInfoBase.team_name : ''}
                                    </Typography>                                                           
                                </Stack>
                            </Stack>

                            {/* Punteggio e Valore */}
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center" 
                                spacing={1} 
                                sx={{width:'100%'}}
                            >
                                {/* Punteggio */}
                                <Stack
                                    direction="column" 
                                    justifyContent="center" 
                                    alignItems="center" 
                                    spacing={0.5}
                                    sx={{
                                        padding:'20px 20px', 
                                        borderWidth:'2px',
                                        borderStyle:'solid',
                                        borderColor:color || theme.palette.primary.main,
                                        borderRadius:'5px'
                                    }}
                                >
                                    <Typography 
                                        variant='titleSection'
                                        sx={{
                                            fontSize:{xs:'22px',md:'20px',sm:'20px'},
                                            fontWeight:'600',
                                            color:theme.palette.custom.black,
                                            textTransform:'capitalize'
                                        }}
                                    >
                                        {t("global.points")}
                                    </Typography>
                                    <Typography
                                        variant='titleSection'
                                        sx={{
                                            color:color || theme.palette.primary.main,
                                            fontSize:{xs:'36px',md:'36px',sm:'36px'},
                                            fontWeight:'600' 
                                        }}
                                    >
                                        {points}
                                    </Typography>
                                    
                                </Stack>
                            
                                {/* Valore */}
                                <Stack
                                    direction="column" 
                                    justifyContent="center" 
                                    alignItems="center" 
                                    spacing={0}
                                    className='wrapperBorder'
                                    sx={{
                                        padding:'10px 20px',                                    
                                        backgroundColor:color + ' !important'
                                    }}
                                >                               
                                    <Typography 
                                        variant='titleSection'
                                        sx={{
                                            fontSize:{xs:'22px',md:'22px',sm:'20px'},
                                            fontWeight:'600',
                                            color:theme.palette.custom.white,
                                            textTransform:'capitalize'
                                        }}
                                    >
                                        {t("global.value")}
                                    </Typography>
                                    <Box>
                                        <PlayerValue 
                                            value={playerInfoBase ? playerInfoBase.player_value : ''} 
                                            hideMio={true} 
                                            color={theme.palette.custom.white}
                                        />
                                    </Box>
                                    <Typography
                                        variant='titleSection'
                                        sx={{
                                            color:theme.palette.custom.white,
                                            fontSize:{xs:'14px',md:'16px',sm:'16px'}
                                        }}
                                    >
                                        Mio €
                                    </Typography>
                                </Stack> 
                            </Stack>
                        </Stack>
                        {/* Info */}
                        <Stack 
                            direction="column" 
                            justifyContent="flex-start"
                            alignItems={{xs:'center',md:'flex-end',sm:'flex-end'}}
                            spacing={1}
                            sx={{width:'100%'}}
                        >                            
                            <Box
                                className='wrapperBorder' 
                                sx={{                                    
                                    borderColor:color + ' !important',                                    
                                    width:{xs:'100%',md:'100%',sm:'100%'},
                                    margin:{xs:'0 auto !important',md:'0px 0 0 0px !important',sm:'0px 0 0 50px !important'}                                  
                                }}
                            >   
                                {playerInfoBase && <PlayerInfo player={playerInfoBase} />}
                            </Box>
                        </Stack>                                                
                        
                        {/* Posizione in campo */}                        
                        <a name="position" />
                        <Stack 
                            direction="column" 
                            justifyContent="flex-start" 
                            alignItems="flex-start" 
                            spacing={0.5}
                            className='wrapperBorder'
                            sx={{width:'100%'}}
                        >
                            <Stack direction="row" spacing={0.5} justifyContent="flex-start" alignItems="center">
                                <PersonPinCircleIcon sx={{color:color,fontSize:'30px'}} />
                                <Box>
                                    <Typography 
                                        variant='titleSection'
                                        sx={{
                                            fontSize:{xs:'22px',md:'22px',sm:'22px'},
                                            color:theme.palette.custom.black,
                                            fontWeight:'600'                   
                                        }}
                                    >
                                        {t("global.playerpositions")}
                                    </Typography>                                    
                                </Box>
                            </Stack>                            
                            <Typography 
                                variant='titleSection'
                                sx={{
                                    fontSize:{xs:'16px',md:'15px',sm:'15px'},
                                    fontWeight:'600',
                                    color:theme.palette.custom.blueIcon
                                }}
                            >  
                                {t("global.playerpositionsdesc")}
                            </Typography>                            
                            <Box                             
                                sx={{                            
                                    width:'100%',                                
                                    padding:{xs:'0 !important',md:'0px !important',sm:'0px !important'},
                                    margin:{xs:'20px 0 20px 0 !important',md:'20px 0 0px 0px !important',sm:'20px 0 30px 0px !important'}
                                }}
                            >
                                {playerInfoBase && <FieldPosition player={playerInfoBase} />}
                            </Box>
                        </Stack>

                        {/* Trand */}
                        <a name="trand" />
                        <Box
                            className='wrapperBorder'
                            sx={{width: '100%'}}
                        >                            
                            <PlayerTrand userLogged={userLogged} />
                        </Box>

                        {/* Coefficienti prestazionali */}
                        <a name="coefficients" />
                        <Box 
                            className='wrapperBorder'
                            sx={{width:'100%'}}
                        >
                            <CoefficientBox userLogged={userLogged} />
                        </Box>
                        
                        {/* Incidenza in team */}
                        <a name="incidence" />
                        <Box className='wrapperBorder' sx={{width:'100%'}}>
                            <PlayerIncidence userLogged={userLogged} />
                        </Box>                        

                        {/* Indici di scostamento */}
                        <a name="deviation" />   
                        <Box className='wrapperBorder' sx={{width:'100%'}}>                        
                            <PlayerDeviation userLogged={userLogged} />
                        </Box>

                        {/* Indici d'efficacia */}
                        <a name="overview" />
                        <Box className='wrapperBorder' sx={{width:'100%'}}>
                            <PlayerOverview userLogged={userLogged} ruolo={ruolo}  />
                        </Box>

                        {/* Parametri valutativi */}
                        <a name="evaluation" />
                        <Box className='wrapperBorder' sx={{width:'100%'}}>
                            <PlayerCalcData userLogged={userLogged} />
                        </Box>

                        {/* Statistiche generali */}
                        <a name="stats" />
                        <Box className='wrapperBorder' sx={{width:'100%'}}>
                            <PlayerStatistics userLogged={userLogged} />
                        </Box> 

                    </Stack>
                    {/* sezione dx */} 
                    <Stack 
                        direction="column" 
                        justifyContent="center"
                        alignItems={{xs:"center",md:"center",sm:"center"}}
                        spacing={2}
                        sx={{
                            width:{xs:'100%',md:'45%',sm:'100%'},
                            marginTop:{xs:'30px !important',md:'0px !important',sm:'30px !important'}
                        }}
                    >
                        {/* Social */}
                        <SocialMediaShare 
                            url={window.location.href} 
                            quote={
                                playerInfoBase ? 
                                    t("social.share.player") + 
                                        playerInfoBase.first_name + ' ' + playerInfoBase.last_name + 
                                        ' (' + playerInfoBase.team_name + ')'
                                    : t("social.share.playernoname")
                            }
                            hashtag={[t("social.hashtag.calciomercato"),t("social.hashtag.calcio")]}
                            propsadd={{marginBottom:'20px'}}
                        />
                        
                        {/* Banner */}                        
                        <Banner type={'300x300_3'} />

                        {/* Altri giocatori della squadra */}
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{
                                width:{xs:'100%',md:'50%',sm:'60%',tb:'60%'},
                                marginTop:{xs:'40px !important',md:'40px !important',sm:'40px !important'}
                            }}
                        >
                            <Typography variant='titleAside'>{parse(t("pagine.player.othersplayers"))}</Typography>
                            <Typography variant='titleAside' sx={{fontSize:'21px !important'}}>
                                {playerInfoBase ? playerInfoBase.team_name : ''}
                            </Typography>
                            <Box sx={{width:'100%',textAlign:'right',margin:'-20px 0 !important',marginBottom:'0px !important'}}>
                                <Button 
                                    variant='textAction'
                                    endIcon={<KeyboardDoubleArrowRightIcon />}
                                    href={othersplayersUrl}
                                >
                                    {t("global.veditutti")}
                                </Button>
                            </Box>
                            {loadingFindPlayers && (
                                <CircularProgress size={44} sx={{color:theme.palette.primary.main,marginTop:'20px !important'}} />
                            )}
                            {!loadingFindPlayers && playerInfoBase && (
                                <PlayersListTable 
                                    players={
                                        playersByTeam.filter((p,index) => p.player_id != playerInfoBase.player_id 
                                            && index >= indexRandom && index <= indexRandom + 4)
                                    } 
                                    viewTeam={true} 
                                    viewRole={true}
                                />          
                            )}
                        </Stack>

                        {/* Banner */}
                        <Box sx={{marginTop:'30px !important'}}>
                            <Banner type={'300x300_2'} />
                        </Box>

                        {/* Altre squadre della stessa lega */}
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{
                                width:{xs:'100%',md:'50%',sm:'60%',tb:'60%'},
                                marginTop:{xs:'40px !important',md:'40px !important',sm:'40px !important'}
                            }}
                        >
                            <Typography variant='titleAside'>{parse(t("pagine.team.othersteam"))}</Typography>
                            <Typography variant='titleAside' sx={{fontSize:'21px !important'}}>
                                {playerInfoBase ? playerInfoBase.tournament_name : ''}
                            </Typography>
                            <Box sx={{width:'100%',textAlign:'right',margin:'-20px 0 !important',marginBottom:'0px !important'}}>
                                <Button 
                                    variant='textAction'
                                    endIcon={<KeyboardDoubleArrowRightIcon />}
                                    href={otherTeamsUrl}
                                >
                                    {t("global.veditutte")}
                                </Button>
                            </Box>
                            {loadingTeams && (<CircularProgress size={44} sx={{color:theme.palette.primary.main,marginTop:'20px !important'}} />)}
                            {!loadingTeams && (<TeamListTable teamsList={listTeams} linkpage={true} />)}
                        </Stack>

                        {/* Banner */}
                        <Box sx={{marginTop:'30px !important'}}>
                            <Banner type={'300x300_1'} />
                        </Box>
                    </Stack>
                </Stack>
            </Fragment>
            )}                            
        </Stack>
    );
};

export default PlayerProfile;