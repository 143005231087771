import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { useLocation } from 'react-router-dom';
import {Box, IconButton, Avatar, Button, Popover, Backdrop,
    List, ListItem, ListItemButton, ListItemText, ListItemIcon, Stack, Typography} from '@mui/material'; 
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import getPages from './pages';
import FormLoginSign from '../FormLoginSign';
import UserMenu from './UserMenu';
import SupportBox from '../SupportBox';
import './styles.css';

const Navbar = _ => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const location = useLocation();
    const user = useStoreState(state => state.user.user);        
    const userLogged = user == null ? false : user !== null && user.logged && user.token !== '';
    const openMenu = useStoreState(state => state.user.openMenu);
    const setOpenMenu = useStoreActions(dispatch => dispatch.user.setOpenMenu);
    const inVerifiedPage = location && location.pathname.includes("verify");
    const logo = process.env.REACT_APP_PUBLIC_URL + "/assets/images/playerstar.png";
    const linkLogo = process.env.REACT_APP_PUBLIC_URL;
    const pages = getPages();
    const [scroll, setScroll] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState(false);
    const [openNavMenu,setOpenNavMenu] = useState(false);
    const [anchorElSupport, setAnchorElSupport] = useState(false);
    const [openSupport, setOpenSupport] = useState(false);

    /*
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 20);
        });
        setOpenMenu(false);
    }, []);
*/
    const handleOpenNavMenu = (event) => {
        setOpenNavMenu(true);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
        setOpenMenu(true);     
    };

    const handleCloseNavMenu = () => {
        setOpenNavMenu(false);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
        setOpenMenu(false);
    };

    const handleOpenSupport = (event) => {
        setAnchorElSupport(event.currentTarget);
        setOpenSupport(true);
    };

    const handleCloseSupport = () => {        
        setOpenSupport(false);
    };

    return(
        <Stack 
            direction="row" 
            justifyContent="flex-start" 
            alignItems="center" 
            spacing={0}
            sx={{padding:{xs:'4px 10px',md:'4px 30px',sm:'4px 20px'}}}
        >
            <Box>
                <a name="top" href={linkLogo} >
                
                    <img 
                        src={logo} 
                        alt="PlayerStar" 
                        className='LogoPlayerStar' 
                    />
                </a>
            </Box>
            {/* Menù Desktop */}
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{width:'100%',minWidth:'70%',display:{xs:'none',md:'inherit',sm:'none'}}}
            >
                {pages.map((page) => (
                    <Button  
                        key={page.id} 
                        href={page.path} 
                        alt={page.label}
                        variant='menuItem'
                        sx={{
                            color:page.path == location.pathname ? theme.palette.primary.main : 'inherit',
                            "&:hover":{
                                background:'none'
                            }                                            
                        }}
                    >
                        {t(page.label)}
                    </Button>
                ))}
            </Stack>
            <Stack 
                direction="row" 
                justifyContent="flex-end" 
                alignItems="center" 
                spacing={2}
                sx={{width:'100%'}}
            >                
                <Box>
                    <IconButton 
                        onClick={handleOpenSupport}                         
                        sx={{p:0, color:theme.palette.custom.black}}
                    >
                        <SupportAgentIcon sx={{fontSize:'42px'}} />
                    </IconButton>
                    <Box>
                        <Backdrop
                            open={openSupport && Boolean(anchorElSupport)}
                            sx={{
                                zIndex:'1',
                                backgroundColor:'rgba(0, 0, 0, 0.5)',
                                transition:'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                zIndex:'2'   
                            }}
                            onClick={_ => {setOpenSupport(false);}} 
                        />
                        <Popover
                            id="mobile-menu-avatar"
                            sx={{ mt: '45px' }}
                            open={Boolean(anchorElSupport) && openSupport}
                            anchorEl={anchorElSupport}
                            onClose={handleCloseSupport}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Box sx={{minWidth:{xs:'345px',md:'345px'}}}>
                                <SupportBox onClose={handleCloseSupport} />
                            </Box>
                        </Popover>
                    </Box>
                </Box>
                {/* User Menù (se non sono nella pagina di verifica email) */}
                {!inVerifiedPage && (
                <Box>
                    <IconButton onClick={handleOpenUserMenu} sx={{p:0}}>
                        <Avatar 
                            sx={{backgroundColor:userLogged ? theme.palette.primary.main: '#bdbdbd'}}
                        >
                            <PersonIcon />
                        </Avatar>
                    </IconButton>                  
                    <Box>
                        <Backdrop 
                            open={openMenu && Boolean(anchorElUser)} 
                            sx={{
                                zIndex:'1',
                                backgroundColor:'rgba(0, 0, 0, 0.5)',
                                transition:'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                zIndex:'2'                                                                        
                            }} 
                            onClick={_ => {setOpenMenu(false);}} 
                        />
                        <Popover
                            id="mobile-menu-avatar"
                            sx={{ mt: '45px' }}
                            open={Boolean(anchorElUser) && openMenu}
                            anchorEl={anchorElUser}
                            onClose={handleCloseUserMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Box sx={{minWidth:{xs:'345px',md:'345px'}}}>
                                {!userLogged && (<FormLoginSign /> )}
                                {userLogged && (
                                    <UserMenu 
                                        open={openMenu} 
                                        anchorEl={anchorElUser}
                                    />
                                )}
                            </Box>
                        </Popover>    
                    </Box> 
                </Box>
                )}
                {/* Menù Mobile */}
                <Box sx={{display:{ xs:'inherit',md:'none',sm:'inherit'}}}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                        >
                        <MenuIcon sx={{fontSize:'36px'}} />
                    </IconButton>
                    <SwipeableDrawer
                        id="mobile-menu-appbar"
                        anchor='right'
                        open={openNavMenu}
                        onClose={handleCloseNavMenu}
                        onOpen={handleOpenNavMenu}                        
                    >
                        <Stack 
                            direction="row" 
                            justifyContent="flex-start" 
                            alignItems="center" 
                            spacing={0} 
                            sx={{
                                width:'100%',
                                borderWidth:'0 0 0px 0',
                                borderStyle:'solid',
                                borderColor:theme.palette.custom.black,
                                padding:'10px 0 10px 10px'
                            }}
                        >
                            <Box sx={{textAlign:'left'}}>
                                <img src={logo} alt="PlayerStar" width="150px" />
                            </Box>
                            <Box sx={{width:'100%',textAlign:'right'}}>
                                <IconButton onClick={handleCloseNavMenu} size="large">
                                    <CloseIcon sx={{color:theme.palette.custom.black}} />
                                </IconButton>
                            </Box>
                        </Stack>
                        <List sx={{padding:'0px 0  0 0'}}>
                            {pages.map((page) => (
                                <ListItem 
                                    key={page.id} 
                                    sx={{
                                        padding:'6px 10px 6px 20px !important',
                                        backgroundColor: page.path == location.pathname ? 
                                        theme.palette.primary.main + ' !important' : 'inherit'
                                    }}
                                >
                                    <ListItemButton
                                        href={page.path}
                                        sx={{padding:'0'}}                                 
                                    >
                                        <ListItemIcon 
                                            sx={{
                                                minWidth:'30px',
                                                color:page.path == location.pathname ? 
                                                    theme.palette.custom.white : 'inherit'
                                            }}
                                        >
                                            <ArrowRightIcon sx={{fontSize:'26px'}} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            variant='menuMobileItem'
                                            primary={
                                                <Typography variant='menuMobileItem' sx={{fontSize:'20px !important'}}>
                                                    {t(page.label)}
                                                </Typography>
                                            }
                                            sx={{
                                                color:page.path == location.pathname ? 
                                                    theme.palette.custom.white : 'inherit'
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </SwipeableDrawer>
                </Box>
            </Stack>
        </Stack>
    );
};

export default Navbar;