import React, { useEffect, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import parse from 'html-react-parser';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Stack, Typography, Box, List, ListItem, ListItemText, ListItemIcon, Button } from '@mui/material';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import InsightsIcon from '@mui/icons-material/Insights';
import HubIcon from '@mui/icons-material/Hub';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import BarChartIcon from '@mui/icons-material/BarChart';
import CircleIcon from '@mui/icons-material/Circle';
import AddTaskIcon from '@mui/icons-material/AddTask';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import Groups3Icon from '@mui/icons-material/Groups3';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import RestoreIcon from '@mui/icons-material/Restore';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';

const About = props => {
    const {t, i18n} = useTranslation('common');
    const theme = useTheme();     

    const parameters = [        
        {            
            label:'global.coefficients',         
            icon:<BubbleChartIcon />
        },
        {
            label:'global.incidenceinteam',            
            icon:<HubIcon />
        },
        {            
            label:'global.deviationindex',
            icon:<SocialDistanceIcon />
        },
        {
            label:'global.overviewindex',            
            icon:<StackedLineChartIcon />
        },
        {
            label:'global.calcdata',         
            icon:<AlignVerticalCenterIcon />
        },
        {
            label:'global.statisticsplayer',            
            icon:<BarChartIcon />
        }
    ];

    const scopeMaps = [
        {
            label:'coefficients.score',
            icon:<CircleIcon sx={{fontSize:'16px'}} />
        },
        {
            label:'coefficients.pass',
            icon:<CircleIcon sx={{fontSize:'16px'}} />
        },
        {
            label:'coefficients.defence',
            icon:<CircleIcon sx={{fontSize:'16px'}} />
        },
        {
            label:'coefficients.save',
            icon:<CircleIcon sx={{fontSize:'16px'}} />
        }
    ];

    const profileVoices = [
        {
            label:'pagine.profilevoice.voice1',
            icon:<RestoreIcon sx={{fontSize:'36px'}}/>
        },
        {
            label:'pagine.profilevoice.voice2',
            icon:<Groups3Icon sx={{fontSize:'36px'}}/>
        },
        {
            label:'pagine.profilevoice.voice3',
            icon:<PersonSearchIcon sx={{fontSize:'36px'}}/>
        },
        {
            label:'pagine.profilevoice.voice4',
            icon:<SupervisedUserCircleIcon sx={{fontSize:'36px'}}/>
        },
        {
            label:'pagine.profilevoice.voice5',
            icon:<SwitchAccessShortcutAddIcon sx={{fontSize:'36px'}}/>
        }        
    ];

    return(
        <Stack 
            direction="column" 
            justifyContent="center" 
            alignItems="center" 
            spacing={2}
            sx={{
                width:'100%',
                padding:{xs:'20px 20px 0px 20px',md:'20px 90px 0px 90px',sm:'20px 40px 0px 40px'}                
            }}
        >
            {/* HERO */}
            <Stack 
                direction={{xs:'column',md:'row',sm:'row'}} 
                justifyContent="center" 
                alignItems="center" 
                spacing={2}
                sx={{width:'100%'}}
            >
                <Stack 
                    direction="column" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={1}
                    sx={{
                        width:{xs:'100%',md:'60%',sm:'60%'},
                        paddingRight:{xs:'0',md:'40px',sm:'0px'}
                    }}
                >
                    <Typography variant='titleHero'>{parse(t("pagine.about.title"))}</Typography>
                    <Typography variant='subtitleHero' sx={{marginTop:'20px !important'}}>
                        {parse(t("pagine.about.subtitle"))}
                    </Typography>
                    <Button 
                        variant='action'
                        endIcon={<DoubleArrowIcon />}
                        href='/detail'
                        sx={{marginTop:'30px !important'}}
                    >
                        {t("pagine.about.sezione2.action")}
                    </Button>
                </Stack>
                <Box 
                    sx={{
                        width:{xs:'100%',md:'40%',sm:'40%'},
                        textAlign:{xs:'left',md:'left',sm:'center'},
                        marginTop:{xs:'20px !important',md:'0 !important',sm:'0px !important'}
                    }}
                >
                    <Box 
                        component="img" 
                        src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/playerstar_calciatore_2.jpg"}
                        sx={{maxWidth:{xs:'350px',md:'500px',sm:'300px'}}}
                    />
                </Box>
            </Stack>

            {/* Perché PlayerStar */}
            <Stack 
                direction={{xs:'column-reverse',md:'row',sm:'column-reverse'}} 
                justifyContent="center" 
                alignItems="center" 
                spacing={2}
                sx={{width:'100%',marginTop:{xs:'20px !important',md:'70px !important',sm:'30px !important'}}}
            >
                <Box sx={{width:{xs:'100%',md:'40%',sm:'100%'},textAlign:{xs:'left',md:'left',sm:'center'}}}>
                    <Box 
                        component="img" 
                        src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/calciatore_playerstar.png"}
                        sx={{maxWidth:{xs:'360px',md:'500px',sm:'400px'}}}
                    />
                </Box>
                <Stack 
                    direction="column" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={2}
                    sx={{
                        width:{xs:'100%',md:'60%',sm:'100%'},
                        padding:{xs:'0',md:'0 30px',sm:'0'}
                    }}
                >
                    <Typography variant='sectionTitle'>{parse(t("pagine.about.sezione1.title"))}</Typography>
                    <Typography variant='text'>{parse(t("pagine.about.sezione1.text1"))}</Typography>
                    <Typography variant='text'>{parse(t("pagine.about.sezione1.text2"))}</Typography>
                    <Typography variant='text'>{parse(t("pagine.about.sezione1.text3"))}</Typography>
                    <Typography variant='text'>{parse(t("pagine.about.sezione1.text4"))}</Typography>
                </Stack>                
            </Stack>

            {/* Come funziona PlayerStar */}
            <Stack 
                direction={{xs:'column',md:'row',sm:'column'}} 
                justifyContent="center" 
                alignItems="center" 
                spacing={2}
                sx={{width:'100%',marginTop:{xs:'40px !important',md:'90px !important',sm:'20px !important'}}}
            >
                <Stack 
                    direction="column" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={1}
                    sx={{
                        width:{xs:'100%',md:'50%',sm:'100%'},
                        paddingRight:{xs:'0',md:'40px',sm:'40px'}
                    }}
                >
                    <Typography variant='sectionTitle'>{parse(t("pagine.about.sezione2.title"))}</Typography>
                    <Typography variant='text'>{parse(t("pagine.about.sezione2.text1"))}</Typography>
                    <List>
                    {parameters.map((parameter,index) => (
                        <ListItem disablePadding key={index}>
                            <ListItemIcon sx={{color:theme.palette.custom.blueIcon,minWidth:'30px'}}>
                                {parameter.icon}
                            </ListItemIcon>
                            <ListItemText 
                                variant='menuMobileItem'
                                primary={<Typography variant='text'>{t(parameter.label)}</Typography>} 
                                sx={{margin:'0 !important',color:theme.palette.custom.blueIcon}}
                            />
                        </ListItem>
                    ))}
                    </List>
                    <Typography variant='text'>{parse(t("pagine.about.sezione2.text2"))}</Typography>
                    <List>
                    {scopeMaps.map((scope,index) => (
                        <ListItem disablePadding key={index}>
                            <ListItemIcon sx={{color:theme.palette.custom.blueIcon,minWidth:'30px'}}>
                                {scope.icon}
                            </ListItemIcon>
                            <ListItemText 
                                variant='menuMobileItem'
                                primary={<Typography variant='text'>{t(scope.label)}</Typography>} 
                                sx={{margin:'0 !important',color:theme.palette.custom.blueIcon}}
                            />
                        </ListItem>
                    ))}
                    </List>                    
                    <Button 
                        variant='action'
                        endIcon={<DoubleArrowIcon />}
                        href='/detail'
                        sx={{marginTop:'20px !important'}}
                    >
                        {t("pagine.about.sezione2.action")}
                    </Button>
                </Stack>
                <Box 
                    sx={{
                        width:{xs:'100%',md:'40%',sm:'100%'},
                        textAlign:{xs:'left',md:'left',sm:'center'},
                        marginTop:{xs:'40px !important',md:'0 !important',sm:'40px !important'}
                    }}>
                    <Box 
                        component="img" 
                        src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/calciatore playerstar valore.jpg"}
                        sx={{maxWidth:{xs:'350px',md:'580px',sm:'450px'}}}
                    />
                </Box>
            </Stack>

            {/* Profilo dei calciatori */}
            <Stack 
                direction="column" 
                justifyContent="center" 
                alignItems="center" 
                spacing={1}
                sx={{
                    margin:{xs:'30px 0 0 0 !important',md:'90px 0 10px 0 !important',sm:'50px 0 0 0 !important'},
                    width:'100%'                    
                }}
            >
                <Typography 
                    variant='sectionTitle' 
                    sx={{textAlign:'center'}}
                >
                    {parse(t("pagine.about.sezione3.title"))}
                </Typography>
                <Typography 
                    variant='text' 
                    sx={{fontWeight:'600',textAlign:{xs:'left',md:'center',sm:'center'}}}
                >
                    {parse(t("pagine.about.sezione3.text1"))}
                </Typography>
            </Stack>
            <Stack 
                direction={{xs:'column-reverse',md:'row',sm:'row'}}
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                sx={{width:'100%'}}
            >
                <Box 
                    sx={{
                        width:{xs:'100%',md:'50%',sm:'40%'},
                        textAlign:{xs:'center',md:'left',sm:'center'},
                        marginTop:{xs:'40px !important',md:'0 !important',sm:'40px !important'}
                    }}>
                    <Box 
                        component="img" 
                        src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/playerstar_profilo_calciatori.jpg"}
                        sx={{maxWidth:{xs:'260px',md:'400px',sm:'260px'}}}
                    />
                </Box>
                <Stack 
                    direction="column" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={1}
                    sx={{
                        width:{xs:'100%',md:'50%',sm:'60%'},
                        marginTop:{xs:'0',md:'-30px !important',sm:'0'}
                    }}
                >
                    <List>
                    {profileVoices.map((voice,index) => (
                        <ListItem key={index} sx={{padding:{xs:'8px 0',md:'8px 0',sm:'4px 0'}}}>
                            <ListItemIcon 
                                sx={{
                                    color:theme.palette.custom.blueIcon,
                                    //minWidth:'40px'
                                }}                                
                            >
                                {voice.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography 
                                        variant='textRound' 
                                        sx={{
                                            fontWeight:'600',
                                            fontSize:{xs:'18px',md:'20px',sm:'20px'}
                                        }}
                                    >
                                        {parse(t(voice.label))}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    ))}
                    </List>
                    <Button 
                        variant='action'
                        sx={{marginTop:{xs:'20px !important',md:'30px !important',sm:'10px !important'}}}
                        endIcon={<DoubleArrowIcon />}
                        href='/players'
                    >
                        {t("pagine.about.sezione3.action")}
                    </Button>
                </Stack>                
            </Stack>

        </Stack>
    );
};

export default About;