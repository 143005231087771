import React, {useState} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

const DialogRegister = props => {
    const {open, onClose, values} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);
    const resetRegistred = useStoreActions(dispatch => dispatch.user.resetRegistred);    
    const errorServiceRegistred = useStoreState(state => state.user.errorServiceRegistred);
    const loadingDialog = useStoreState(state => state.user.loadingDialog);
    const resendedEmailConfirm = useStoreState(state => state.user.resendedEmailConfirm);
    const messageResendedEmailConfirm = useStoreState(state => state.user.messageResendedEmailConfirm);
    const resendEmailConfirm = useStoreActions(dispatch => dispatch.user.resendEmailConfirm);
    const [resend,setResend] = useState(false);
    const tilteDialog = loadingDialog ? parse(t("global.wait")) :
        (errorServiceRegistred === '' && !resend && !loadingDialog) || 
        (resend && resendedEmailConfirm && !loadingDialog) ?
            parse(t("global.youalmostthere")) : 
        (errorServiceRegistred !== '' && !resend) || (resend && !resendedEmailConfirm) ?
            parse(t("global.oops")) : '';
    const textAccountCreated = (errorServiceRegistred === '' && !resend) ?  parse(t("formlogin.accountcreated")) :
        (errorServiceRegistred !== '' & !resend) ? parse(t("formlogin.accountcreatederror")) :
        (resend && !resendedEmailConfirm) ? parse(t("formlogin.accountcreatederror")) : '';    
    const textDialog = (errorServiceRegistred === '' && !resend) ? parse(t("formlogin.registredemail")) :
        (errorServiceRegistred !== '' && !resend) ? parse(t('formlogin.' + errorServiceRegistred)) :
        resend ? parse(t("formlogin." + messageResendedEmailConfirm)) : '';    
    const textLinkEmail = parse(t("formlogin.resendemailconfirm"));
    const colorTitleDialog = (errorServiceRegistred === '' && !resend) || 
        (resend && resendedEmailConfirm) || loadingDialog ? 
            theme.palette.custom.black : 
        (errorServiceRegistred !== '' && !resend) || (resend && !resendedEmailConfirm) ?
        theme.palette.error.main : errorServiceRegistred;
    
    const handleClose = () => {
        resetRegistred();
        onClose();
    };

    const resendConfirm = () => {
        setResend(true);
        resendEmailConfirm({
            email: values.email,
            password: values.password,
            name: values.name,
            lang: i18n.language
        });
    }; 

    return(
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-registred-title"
                variant='primary'             
            >
                <DialogTitle id="dialog-registred-title" sx={{textAlign:'center'}}>
                    <Stack spacing={2}>
                        {loadingDialog && (
                            <CircularProgress 
                            sx={{
                                fontSize:"70px",
                                margin:'0 auto -10px'
                            }}
                            />
                        )}                        
                        {!loadingDialog && ( 
                            (errorServiceRegistred === '' && !resend) || (resend && resendedEmailConfirm)) && (
                        <DoneIcon 
                            sx={{
                                fontSize:"70px",
                                margin:'0 auto -10px',                                
                                color:`${theme.palette.success.main}`}}                                
                        />
                        )}
                        {!loadingDialog && (
                            (errorServiceRegistred !== '' && !resend) || (resend && !resendedEmailConfirm)) && (
                        <CloseIcon
                            sx={{
                                fontSize:"70px",
                                margin:'0 auto -10px',                                
                                color:`${theme.palette.error.main}`}} 
                        />
                        )}
                        <Typography variant='titleSection' color={colorTitleDialog}>
                            {tilteDialog}
                        </Typography>
                        <Typography variant='titleSection' sx={{fontSize:{xs:'20px',md:'22px'}}}>
                            {!loadingDialog ? textAccountCreated : ''}
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{textAlign:'center'}} component='div'>
                        <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                            <Typography variant='textBase' sx={{color:'#000',lineHeight:'1.3em'}}>
                                {!loadingDialog ? textDialog : ''}
                            </Typography>                            
                            {!resend  && (errorServiceRegistred === '' || errorServiceRegistred === 'emailtaken') && (
                                <Link 
                                    href='#' 
                                    onClick={resendConfirm} 
                                    sx={{color:theme.palette.custom.orange + ' !important'}}
                                >
                                    {textLinkEmail}
                                </Link>
                            )}
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{margin:'0 auto',width:'40%'}}>
                    <Button 
                        autoFocus 
                        variant='action' 
                        onClick={handleClose} 
                        sx={{width:'100%',margin:'10px auto'}} 
                    >
                        {parse(t("global.close"))}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DialogRegister;