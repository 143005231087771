import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Logout from '@mui/icons-material/Logout';
import Person2Icon from '@mui/icons-material/Person2';
import CircularProgress from '@mui/material/CircularProgress';

const UserMenu = props => {
    const {open, anchorEl } = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const loading = useStoreState(state => state.user.loading);
    const logout = useStoreActions(dispatch => dispatch.user.logout);
    const pages = [
        {icon: <Person2Icon />, label: parse(t("global.account")), href: '/account'}
    ];

    const handleLogout = e => {
        logout();        
    };

    return(
        <Box sx={{ display: 'flex' }}>
            <Stack sx={{width:'100%'}}>
                <List variant='popupmenu'>
                    {pages.map((page) => (
                        <ListItemButton key={page.label} href={page.href}>
                            <ListItemIcon sx={{color:theme.palette.custom.black}}>{page.icon}</ListItemIcon>                            
                            <ListItemText 
                                variant='menuMobileItem'
                                primary={
                                    <Typography variant='menuMobileItem' sx={{fontSize:'18px !important'}}>
                                        {t(page.label)}
                                    </Typography>
                                }
                            >

                            </ListItemText>
                        </ListItemButton>
                    ))}
                </List>
                <Button variant='iconFull' onClick={handleLogout} sx={{marginTop:'-10px'}}>
                    <Stack 
                        direction="row" 
                        spacing={0}
                        alignItems="center" 
                        justifyContent="center"        
                    >
                        <Box sx={{
                            padding:'0 20px',
                            lineHeight:'60px',
                            position:'absolute',
                            left:'0',
                            backgroundColor:`${theme.palette.custom.yellowDark}`
                            }}
                        >
                            <Logout />
                        </Box>
                        {loading && (<CircularProgress sx={{color:'#fff'}} />)}
                        {!loading && (
                        <Typography variant='textBase' sx={{fontWeight:'600'}}>
                            {t("formlogin.logout")}
                        </Typography>
                        )}
                    </Stack>    
                </Button> 
            </Stack>
        </Box>
    );
};

export default UserMenu;