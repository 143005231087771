import React, {useState} from 'react';
import { useStoreState } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { Stack, Box, Typography, CircularProgress, duration, easing } from '@mui/material';
import { Chart as ChartJS, defaults, plugins } from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import InsightsIcon from '@mui/icons-material/Insights';
import MessageLogin from '../MessageLogin';
import { fromPairs, size } from 'lodash';

const PlayerTrand = props => {
    const {userLogged} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);    
    const loadingTrand = useStoreState(state => state.players.getPlayer.loadingTrand);
    const playerTrand = useStoreState(state => state.players.getPlayer.playerTrand);
    const playersLimits = useStoreState(state => state.players.playerSummary.playersLimits);
    const valueLimitsMax = playersLimits.filter(val => val.type === "max")[0];
    const valueLimitsMin = playersLimits.filter(val => val.type === "min")[0];

    //Se non ho ancora caricato i dati esco
    if(!playerTrand)
        return;
    
    const extractTrand = (list,key) => {
        const info = list.split(";").filter(item => item.includes(key + ":"));
        if(info && info.length > 0)
            return parseFloat(info[0].replace(key + ":",""),10);
        else
            return null;
    };

    //Dati dei grafici
    const allData = playerTrand ? 
        Object.keys(playerTrand).filter(k => k.includes("d")).map((key,index) => {return {day: index + 1, trand: playerTrand[key]};})
        : null;
    const allDataNotNull = allData.map(item => {if(item.trand != null) return item}).filter(i => i);
    const trand = allDataNotNull.length >= 5 ? allDataNotNull.slice(-5) : allDataNotNull;
    const labels = trand.map(item => item.day);
    const dataset = [             
        {
            label:t('coefficients.score'),
            data:trand.map(item => extractTrand(item.trand,"sc")),
            backgroundColor: theme.palette.custom.at,
            borderColor: theme.palette.custom.at
        },
        {
            label:t('coefficients.pass'),
            data:trand.map(item => extractTrand(item.trand,"pa")),
            backgroundColor: theme.palette.custom.cc,
            borderColor: theme.palette.custom.cc
        },
        {
            label:t('coefficients.defence'),
            data:trand.map(item => extractTrand(item.trand,"de")),
            backgroundColor: theme.palette.custom.de,
            borderColor: theme.palette.custom.deo
        },
        {
            label:t('coefficients.save'),
            data:trand.map(item => extractTrand(item.trand,"sa")),
            backgroundColor: theme.palette.custom.gk,
            borderColor: theme.palette.custom.gk
        },
    ];
    
    const datasetsValori = [             
        {
            label:t('global.value'),
            data:trand.map(item => extractTrand(item.trand,"pl_va")),
            backgroundColor: theme.palette.custom.co,
            borderColor: theme.palette.custom.co
        },
        {
            label:t('global.points'),
            data:trand.map(item => extractTrand(item.trand,"va")),
            backgroundColor: theme.palette.custom.gk,
            borderColor: theme.palette.custom.gk
        }        
    ]
    
    //Opzioni dei grafici
    let options = {
        elements:{
            line:{
                tension:0.4,
                borderJoinStyle:'round',
                borderDashOffset:0.3
            }
        },                            
        plugins: {
            legend:{                                  
                labels:{
                    font:{
                        size:14,
                        weight:600
                    }
                }                                    
            },
            title: {
                display: true,
                text:t('global.coefficients'),
                font:{
                    size:18,
                    weight:600
                },
                padding: {
                    top: 10,
                    bottom: 0
                }
            }
        }
    };
    
    //Clono le opzioni per il grafico dei valori e modifico il titolo
    let optionsValue = JSON.parse(JSON.stringify(options));
    optionsValue.plugins.title.text = t('global.value');
    //Massimo e minimo asse Y
    const scalesValue = {
        y: {
            suggestedMin: Math.round(valueLimitsMin.player_value),
            suggestedMax: Math.round(valueLimitsMax.player_value) + 1
        }
    };
//    optionsValue.scales = scales; -> Per ora non inserisco la scala dei max e min perchè con pochi valori la linea è sempre piatta 

    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
        >
            <Stack direction="row" spacing={0.5} justifyContent="flex-start" alignItems="center">
                <InsightsIcon sx={{color:theme.palette.primary.main,fontSize:'30px'}} />
                <Box>
                    <Typography 
                        variant='titleSection'
                        sx={{
                            fontSize:{xs:'22px',md:'22px',sm:'22px'},
                            color:theme.palette.custom.black,
                            fontWeight:'600'                   
                        }}
                    >
                        {t("global.trand")}
                    </Typography>                    
                </Box>
            </Stack>

            {!userLogged && (<MessageLogin data={t("messages.values")} color={theme.palette.custom.black} />)}

            {loadingTrand && (
                <Box sx={{margin:'30px auto 20px',textAlign:'center'}}>
                    <CircularProgress 
                        size="80px"
                        sx={{color:theme.palette.primary.main}} 
                    />
                </Box>
            )}

            {!loadingTrand && userLogged && (
                 <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                    sx={{width:'100%'}}
                >
                     
                    <Line
                        data={{
                            labels:labels,
                            datasets:datasetsValori
                        }}
                        options={optionsValue}
                    />
                    
                    <Line
                        data={{
                            labels:labels,
                            datasets:dataset
                        }}
                        options={options}
                    />                    
                </Stack>
            )}
        </Stack>
    );
};

export default PlayerTrand;