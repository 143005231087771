import React, {useEffect, useState} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation, Trans} from "react-i18next";
import parse from 'html-react-parser';
import { Stack, Box, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Typography, Button } from '@mui/material';
import PersonOffIcon from '@mui/icons-material/PersonOff';

const DialogLogin = props => {
    const {open, onClose} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);
    const loading = useStoreState(state => state.user.loading);
    const errorLogin = useStoreState(state => state.user.errorLogin);  
    const titleDialog = parse(t('formlogin.' + errorLogin))
    const colorTitle = theme.palette.error.main;

    const handleClose = () => {    
        onClose();
    };

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-login-title"
                variant='primary'             
            >
                <DialogTitle id="dialog-login-title" sx={{textAlign:'center'}}>
                    <Stack spacing={2}>                        
                        {errorLogin !== '' && (
                            <PersonOffIcon
                                sx={{
                                    fontSize:"70px",
                                    margin:'0 auto -10px',                                
                                    color:`${theme.palette.error.main}`}} 
                            />
                        )}
                        <Typography 
                            variant='titleSection' 
                            sx={{
                                fontSize:{xs:'20px',md:'22px'},
                                color:colorTitle
                            }}
                        >
                            {titleDialog}
                        </Typography>                        
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{textAlign:'center'}}>  
                    {errorLogin !== '' && (
                        <Typography variant='textBase' sx={{color:'#000'}}>
                            {parse(t('formlogin.' + errorLogin + 'desc'))}
                        </Typography>      
                    )}                  
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{margin:'0 auto',width:'40%'}}>
                    <Button 
                        autoFocus 
                        variant='action' 
                        onClick={handleClose} 
                        sx={{width:'100%',margin:'10px auto'}} 
                    >
                        {parse(t("global.close"))}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DialogLogin;