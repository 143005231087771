const customFetch = (url, options) => {
    return fetch(url, options).then(response => {
        if (response.status >= 200 && response.status < 300) {
			return Promise.resolve(response);
        }
        else{
            const error = new Error(response.statusText || response.status);
            error.response = response;
            return Promise.reject(error);
        }
    });
};

export default customFetch;