import React, {useEffect, useState} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormLogin from './FormLogin';
import FormSignIn from './FormSignIn';

const TabPanel = props => {
    const { children, value, index, ...other } = props; 

    return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box>{children}</Box>
          )}
        </div>
      );
};

const FormLoginSign = props => {
    const { onClose } = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const labelLogin = parse("<strong>" + t("formlogin.login") + "</strong>");
    const labelSignIn = parse("<strong>" + t("formlogin.signin") + "</strong>");
    const [value, setValue] = useState(1);

    const handleChange = (e,newValue) => {
        setValue(newValue);
    };

    return(       
        <Box>            
            <AppBar position='static' variant='formLoginSign'>
                <Tabs 
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor='#fff'
                >
                    <Tab 
                        label={labelLogin}
                        value={1} 
                        id="tab-1" 
                        aria-controls="tabpanel-1"
                        variant="formLoginSign"
                    />
                    <Tab 
                        label={labelSignIn}
                        value={2} 
                        id="tab-2" 
                        aria-controls="tabpanel-2"
                        variant="formLoginSign"
                    />
                </Tabs>
            </AppBar>
            <Box>
                <TabPanel index={1} value={value}>
                    <FormLogin />
                </TabPanel>
                <TabPanel index={2} value={value}>
                    <FormSignIn />
                </TabPanel>
            </Box>
        </Box>
    );
};

export default FormLoginSign;